import {Component, EventEmitter, inject, Input, Output, TemplateRef} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-fnp-modal',
    templateUrl: './fnp-modal.component.html',
    styleUrls: ['./fnp-modal.component.scss']
})
export class FnpModalComponent {

    private _modalService: NgbModal = inject(NgbModal);

    @Input()
    public title: string;
    @Input()
    public contentTemplate: TemplateRef<any>;
    @Input()
    public cancelButtonLabel = 'GLOBAL.CANCEL';
    @Input()
    public submitButtonLabel = 'GLOBAL.SAVE';
    @Input()
    public saveAction: boolean;
    @Input()
    public invalidForm: boolean;
    @Input()
    public modalError?: { message: string };
    @Output()
    public submitAction$: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    public cancelAction$: EventEmitter<void> = new EventEmitter<void>();

    public closeModal(): void {
        this._modalService.dismissAll();
        this.cancelAction$.emit();
    }

    public submit(): void {
        this.submitAction$.emit();
    }

}
