import {Component, inject, OnInit} from '@angular/core';
import {DialogService} from '../../../services/dialog.service';
import {AuthService} from '../../../services/auth.service';
import {NewsletterService} from '../../../services/newsletter.service';
import {ROLE} from '../../../utils/enums/role';
import {
    prepareAndDownloadFileAction,
    getFilenameFromHeaders,
    getMessageFromError
} from '../../../utils/other/utilities';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    public visibilityClass = 'collapse';
    public adminActive = false;
    private _newsletterService: NewsletterService = inject(NewsletterService);

    private _authService: AuthService = inject(AuthService);
    private _dialogService: DialogService = inject(DialogService);

    public ngOnInit(): void {
        this.adminActive = this._authService.userRole === ROLE.ADMIN;
    }

    public toggleMenu(): void {
        this.visibilityClass = (this.visibilityClass === '' ? 'collapse' : '');
    }

    public downloadNewsletterFile(event) {
        event.preventDefault();
        this._newsletterService.getNewsletterFile().subscribe({
            next: (response) =>
                prepareAndDownloadFileAction(response.body, getFilenameFromHeaders(response.headers), 'application/pdf'),
            error: (error) => this.showErrorFromPossibleBlob(error)
        });
    }

    private showErrorFromPossibleBlob(error: any) {
        const errPromise: Promise<string> = (error?.error as Blob)?.text();
        if (errPromise) {
            errPromise.then((finalTxt) => this.displayErrorModal(finalTxt));
        } else {
            this.displayErrorModal(error);
        }
    }

    private displayErrorModal(error: any): void {
        const dialogContent = this._dialogService.getNewDialogContent(
            'error',
            null,
            null,
            `ERROR.${getMessageFromError(error)}`
        );
        this._dialogService.openInfoDialog(dialogContent);
    }
}
