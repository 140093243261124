import {Component, EventEmitter, inject, Input, Output, ViewChild} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

export interface FnpDialogContent {
    icon: 'success' | 'warning' | 'error';
    title: string;
    text1?: string;
    text2?: string;
    text3?: string;
    buttonLabel: string;
    override?: boolean
}

@Component({
    selector: 'app-dialog',
    templateUrl: './fnp-dialog.component.html',
    styleUrls: ['./fnp-dialog.component.scss']
})
export class FnpDialogComponent {

    @Input() params!: FnpDialogContent;
    @Output() submit$: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('dialogContent') content: any;

    private currentModal?: NgbModalRef;
    private modalService = inject(NgbModal);

    public open(): void {
        this.currentModal = this.modalService.open(this.content, {backdrop: 'static', size: 'lg', centered: true});
    }

    public emitAndClose() {
        this.submit$.emit();
        this.currentModal.close();
    }
}
