<div class="container"
     [ngClass]="(loggedSubject | async) ? 'container--white' : 'container--grey container--border-top'">
    <div class="container__nested">
        <div class="container__start">
            <img class="container__start__logo"
                 src="assets/img/logo-ford.png"
                 alt="Ford logo">
            <span class="container__start__title">
        <span class="container__start__title__stopper"></span>
                {{ 'HEADER.FLEET_NATIONAL_PRICING' | translate }}
        </span>
        </div>
        <div class="container__end">
            <form
                    *ngIf="isAdminLogged"
                    class="container__end__market-form"
                    [formGroup]="marketForm">
                <app-fnp-select
                        formControlName="market"
                        [label]="'HEADER.MARKET'"
                        [valuePropertyName]="'id'"
                        [labelPropertyName]="'name'"
                        [selectOptions]="availableMarkets"
                        [enableTranslation]="true"
                        [translationModule]="'GLOBAL'"
                        (change)="submitMarket()"
                ></app-fnp-select>
            </form>
            <form
                    *ngIf="languageForm && availableLanguages?.length > 1"
                    class="container__end__language-form"
                    [formGroup]="languageForm">
                <app-fnp-select
                        formControlName="language"
                        [label]="'LANGUAGE.LANGUAGE'"
                        [valuePropertyName]="'code'"
                        [labelPropertyName]="'description'"
                        [selectOptions]="availableLanguages"
                        (change)="submitLanguage()"
                ></app-fnp-select>
            </form>

            <div class="container__end__logout">
                <button
                        *ngIf="loggedSubject | async"
                        type="button"
                        class="container__end__logout__button btn"
                        (click)="logout()"
                >{{ "LOGIN.LOGOUT" | translate }}
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="loggedSubject | async" class="container__navbar">
        <app-navbar></app-navbar>
    </div>
    <div id="newsPopup"></div>
</div>
