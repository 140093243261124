import * as Excel from 'exceljs/dist/exceljs.min.js';

export class HeaderBuilder {
    private readonly headers: Excel.Column[];

    constructor() {
        this.headers = [];
    }

    public addHeader(headerName: string, propKey?: string): HeaderBuilder {
        this.headers.push({header: headerName, key: (propKey ?? headerName), width: headerName.length + 20});
        return this;
    }

    public build(): Excel.Column[] {
        return this.headers;
    }
}