<div class="fnp-modal modal show" tabindex="-1" aria-labelledby="exampleModalLabel"
     style="display: block; padding-right: 15px;" aria-modal="true" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="fnp-modal modal-header">
                <h3 class="mb-0 mt-2" id="exampleModalLabel">{{title | translate}}</h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <fa-icon
                            class="fa-sharp fa-regular fa-circle-xmark"
                            [icon]="['far', 'circle-xmark']"
                            (click)="closeModal()"
                    ></fa-icon>
                </button>
            </div>
            <div class="modal-body">
                <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
                <span [ngStyle]="{'visibility' : (modalError ? 'visible' : 'hidden')}"
                      class="error-message">{{('ERROR.' + modalError?.message) | translate}}</span>
            </div>
            <div class="modal-footer">
                <button
                        type="button"
                        class="btn fnp-btn-secondary"
                        data-dismiss="modal"
                        (click)="closeModal()">
                    {{cancelButtonLabel | translate}}
                </button>
                <button
                        *ngIf="saveAction"
                        type="button"
                        class="btn fnp-btn-primary"
                        data-dismiss="modal"
                        [disabled]="invalidForm"
                        (click)="submit()">
                    {{submitButtonLabel | translate}}
                </button>
            </div>
        </div>
    </div>
</div>