import {Component, ElementRef, inject, OnInit, ViewChild} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {CookieService} from 'ngx-cookie-service';
import {KeycloakService} from 'keycloak-angular';

const COOKIE_CONSENT = 'cookie_consent_status';
const COOKIE_CONSENT_EXPIRE_DAYS = 365;
const REGISTRATION_URL = '/registration';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent implements OnInit {

  private modalService = inject(NgbModal);
  private cookieService = inject(CookieService);
  private readonly keycloak = inject(KeycloakService);

  private currentModal?: NgbModalRef;

  @ViewChild('cookieConsentModal', {static: true}) ccModal: ElementRef;

  public ngOnInit() {
    const isConsented = this.cookieService.get(COOKIE_CONSENT) === '1';
    const isRegistrationPage = window.location.pathname == REGISTRATION_URL;
    if (!isConsented && (this.keycloak.getKeycloakInstance().authenticated || isRegistrationPage)) {
      this.currentModal = this.modalService.open(
        this.ccModal, {backdrop: 'static', centered: true, keyboard: true});
    }
  }

  public close() {
    this.cookieService.set(COOKIE_CONSENT, '1', {expires: COOKIE_CONSENT_EXPIRE_DAYS});
    this.currentModal.close();
  }
}
