<!--TODO add Font Awesome license-->
<ng-template #dialogContent let-modal>
  <div *ngIf="params" class="fnp-dialog">
    <fa-icon
      *ngIf="params.icon === 'success'"
      class="fa-sharp fa-circle-check fa-4x"
      [icon]="['fas', 'circle-check']"
      style="color: #00bb2f;">
    </fa-icon>
    <fa-icon
      *ngIf="params.icon === 'warning'"
      class="fa-solid fa-exclamation-triangle fa-4x"
      [icon]="['fas', 'triangle-exclamation']"
      style="color: #dfdf00;">
    </fa-icon>
    <fa-icon
      *ngIf="params.icon === 'error'"
      class="fa-sharp fa-regular fa-circle-xmark fa-4x err-dialog-icon-color"
      [icon]="['far', 'circle-xmark']">
    </fa-icon>
    <h2 class="fnp-dialog__title">{{params.title | translate}}</h2>
    <p class="fnp-dialog__txt" *ngIf="params.text1">{{params.text1 | translate}}</p>
    <p class="fnp-dialog__txt" *ngIf="params.text2">{{params.text2 | translate}}</p>
    <p class="fnp-dialog__txt" *ngIf="params.text3">{{params.text3 | translate}}</p>
    <button class="btn fnp-btn-primary fnp-dialog__button"
          type="button" (click)="emitAndClose()">{{params.buttonLabel | translate}}</button>
  </div>
</ng-template>
