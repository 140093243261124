import {Component, inject, Input} from '@angular/core';
import {FnpFormControlDirective} from '../../../utils/directives/fnp-form-control.directive';

@Component({
    selector: 'app-fnp-select',
    templateUrl: './fnp-select.component.html',
    styleUrls: ['./fnp-select.component.scss'],
    hostDirectives: [FnpFormControlDirective]
})
export class FnpSelectComponent {

    @Input()
    public selectOptions: any;
    @Input()
    public valuePropertyName: string;
    @Input()
    public labelPropertyName: string;
    @Input()
    public label: string;
    @Input()
    public enableTranslation = false;
    @Input()
    public translationModule?: string;

    public formControlDirective: FnpFormControlDirective = inject(FnpFormControlDirective);

}
