<div class="password-input">
    <input
            class="password-input__input"
            [type]="inputType"
            [formControl]="formControl">
    <fa-icon
            class="password-input__icon"
            [icon]="inputType ==='text' ?  ['far', 'eye'] : ['far', 'eye-slash']"
            (click)="toggleInputType()">
    </fa-icon>
</div>