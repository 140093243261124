export interface UploadFnpFile {
    file: File,
    publicationDateTimestamp: number;
}

export interface UpdateFnpFile {
    id: string,
    fileName: string,
    publicationDateTimestamp: number;
}

export interface FnpFileI {
    id?: string,
    fileName: string,
    publicationDate: Date,
    status: FnpFileStatus;
}

export class FnpFile implements FnpFileI {
    id?: string;
    fileName: string;
    publicationDate: Date;
    status: FnpFileStatus;
    file: File;

    constructor(file: File, publicationDate: number) {
        this.file = file;
        this.fileName = file.name;
        this.publicationDate = new Date(publicationDate * 1000);
        this.status = FnpFileStatus.WAITING;
    }

}

export enum FnpFileStatus {
    WAITING = 'WAITING',
    ACTIVE = 'ACTIVE'
}

export enum FnpFileType {
    SERVICE = 'SERVICE',
    REPAIR = 'REPAIR',
    NEWSLETTER = 'NEWSLETTER'
}