import {inject, Injectable} from '@angular/core';
import {NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class BootstrapTranslatedDatepicker extends NgbDatepickerI18n {
    private _translateService = inject(TranslateService);

    public I18N_VALUES = {
        weekdays: ['DATE.MONDAY', 'DATE.TUESDAY', 'DATE.WEDNESDAY', 'DATE.THURSDAY', 'DATE.FRIDAY', 'DATE.SATURDAY', 'DATE.SUNDAY'],
        months: ['DATE.JANUARY', 'DATE.FEBRUARY', 'DATE.MARCH', 'DATE.APRIL', 'DATE.MAY', 'DATE.JUNE', 'DATE.JULY',
            'DATE.AUGUST', 'DATE.SEPTEMBER', 'DATE.OCTOBER', 'DATE.NOVEMBER', 'DATE.DECEMBER'],
        weekLabel: 'DATE.WEEK'
    };

    public getWeekdayLabel(weekday: number): string {
        return (<string>this._translateService.instant(this.I18N_VALUES.weekdays[weekday - 1])).slice(0, 2).toUpperCase();
    }

    public override getWeekLabel(): string {
        return <string>this._translateService.instant(this.I18N_VALUES.weekLabel);
    }

    public getMonthShortName(month: number): string {
        return (<string>this._translateService.instant(this.I18N_VALUES.months[month - 1])).slice(0, 3).toUpperCase();
    }

    public getMonthFullName(month: number): string {
        return <string>this._translateService.instant(this.I18N_VALUES.months[month - 1]);
    }

    public getDayAriaLabel(date: NgbDateStruct): string {
        return `${date.day}-${date.month}-${date.year}`;
    }
}

