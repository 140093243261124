<div class='main-container'>
    <div class='main-container__header'>
        <app-header class='main-container__header__component'></app-header>
    </div>

    <div class='main-container__body'>
        <app-body class='main-container__body__component'></app-body>
    </div>

    <div class='main-container__footer'>
        <app-footer class='main-container__footer__component'></app-footer>
    </div>

    <app-cookie-consent></app-cookie-consent>
    <app-newsletter></app-newsletter>
    <app-dialog
        #dialogComponent
        [params]="dialogContent"
    ></app-dialog>

</div>
<app-fnp-spinner></app-fnp-spinner>
