<div class="fnp-logged-header container-fluid fnp-back-bleed">
    <div>
        <nav class="navbar navbar-expand-md navbar-light">
            <div class="container-fluid">
                <button
                        type="button"
                        class="navbar-toggler position-absolute toggle-bars-override"
                        data-toggle="collapse"
                        data-target="#fnp-navbar"
                        aria-controls="fnp-navbar"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        (click)="toggleMenu()">
                    <fa-icon [icon]="['fas', 'bars']"></fa-icon>
                </button>
                <div class="navbar-collapse" id="fnp-navbar" [ngClass]="visibilityClass">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a class="nav-link"
                               routerLink="intro"
                               routerLinkActive="nav-link-active"
                            >{{'NAVBAR.INTRODUCTION_AND_MAP' | translate}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link"
                               routerLink="fnp"
                               routerLinkActive="nav-link-active"
                            >{{'NAVBAR.FNP' | translate}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link"
                               routerLink="my-details"
                               routerLinkActive="nav-link-active"
                            >{{'NAVBAR.MY_DETAILS' | translate}}</a>
                        </li>
                        <li *ngIf="adminActive" class="nav-item">
                            <a class="nav-link"
                               routerLink="admin"
                               routerLinkActive="nav-link-active"
                            >{{'NAVBAR.ADMIN_PANEL' | translate}}</a>
                        </li>
                        <li *ngIf="adminActive" class="nav-item">
                            <a class="nav-link"
                               routerLink="users-management"
                               routerLinkActive="nav-link-active"
                            >{{'NAVBAR.USER_MANAGEMENT' | translate}}</a>
                        </li>
                    </ul>
                </div>
                <div>
                    <a href="newsletter.pdf"
                        (click)="downloadNewsletterFile($event)" tabindex="-1">{{"NEWSLETTER.LINK" | translate}}</a>
                </div>
            </div>
        </nav>
    </div>
</div>
