import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../config/environments/environment';
import {User} from '../models/user';
import {FnpLanguageService} from './fnp-language.service';

@Injectable({
    providedIn: 'root'
})
export class RegistrationService {

    private _httpClient: HttpClient = inject(HttpClient);
    private _languageService: FnpLanguageService = inject(FnpLanguageService);

    public registerUser(user: User): Observable<any> {
        user.locale = this._languageService.currentLang;
        return this._httpClient.post<User>(`${environment.apiUrl}/users/registration`, user);
    }
}
