import {Environment} from '../../app/models/environment.type';

export const environment: Environment = {
  name: 'UAT',
  appUrl: 'https://app-fnp-fe-uat-westeu-1.azurewebsites.net/',
  apiUrl: 'https://app-fnp-be-uat-westeu-1.azurewebsites.net/api',
  keycloak: {
    clientId: 'fnp-dealer-oidc',
    realm: 'EU',
    url: 'https://account.oecuat.oeconnection.com/auth'
  }
};
