import {Component, inject} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {RouteService} from '../../services/route.service';
import {MarketService} from '../../services/market.service';
import {BehaviorSubject} from 'rxjs';
import {Market} from '../../models/market';
import {FnpLanguageService} from '../../services/fnp-language.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

    private _authService: AuthService = inject(AuthService);
    private _routeService: RouteService = inject(RouteService);
    private _marketService: MarketService = inject(MarketService);
    private _router: Router = inject(Router);
    private _languageService: FnpLanguageService = inject(FnpLanguageService);

    public redirectToPrivacyPolicy(): void {
        if (this.isCurrentlyLogged) {
            window.open(this._marketService.privacyPolicyUrl, '_blank');
        } else {
            console.log(this.currentLanguage.getValue());
            this._router.navigate(['/privacy-policy?lang=' + this.currentLanguage?.getValue()]);
        }
    }

    public get userName(): string {
        return this._authService.userName;
    }

    public get isCurrentRoutePrivacyPolicy(): boolean {
        return this._routeService.currentRoute.split('?')[0] === '/privacy-policy';
    }

    public get isCurrentRouteHelpDesk(): boolean {
        return this._routeService.currentRoute.split('?')[0] === '/help-desk';
    }

    public get currentMarket(): BehaviorSubject<Market> {
        return this._marketService.asyncCurrentMarket();
    }

    public get currentLanguage(): BehaviorSubject<string> {
        return this._languageService.updateLanguage$;
    }

    public get isCurrentlyLogged(): boolean {
        return this._authService.isCurrentlyLogged();
    }

    public getEmailUrl(email: string, subject: string): string {
        return `mailto:${email}?subject=${subject} ${this.userName}`;
    }


}
