import {EventEmitter, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SpinnerService {
    private _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _activateRequest$: EventEmitter<string> = new EventEmitter<string>();
    private _deactivateRequest$: EventEmitter<string> = new EventEmitter<string>();
    private activeSpinners: Set<string> = new Set<string>();

    constructor() {
        this._activateRequest$.subscribe((path) => {
            if (this.activeSpinners.size === 0) {
                this.activeSpinners.add(path);
                this._loading$.next(true);
            }
        });
        this._deactivateRequest$.subscribe((path) => {
            this.activeSpinners.delete(path);
            if (this.activeSpinners.size === 0) {
                this._loading$.next(false);
            }
        });
    }

    public activate(path: string): void {
        this._activateRequest$.next(path);
    }

    public deactivate(path: string): void {
        this._deactivateRequest$.next(path);
    }

    public get loading$(): BehaviorSubject<boolean> {
        return this._loading$;
    }
}
