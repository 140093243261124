import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    public getStored(key: string): any {
        return sessionStorage.getItem(key);
    }

    public setStored(key: string, value: any): void {
        sessionStorage.setItem(key, value);
    }

    public removeStored(key: string): void {
        return sessionStorage.removeItem(key);
    }

    public flushAll(): void {
        sessionStorage.clear();
    }
}
