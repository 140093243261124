<div id="fnp-container" class="fnp">
    <div class="fnp__vehicle-search">
        <div class="fnp__vehicle-search__search">
            <form
                    class="fnp__vehicle-search__search__form"
                    [formGroup]="vehicleFormGroup"
                    (ngSubmit)="onSubmitVehicle()">
                <div class="fnp__vehicle-search__search__form__field">
                    <label
                            for="vinI"
                            class="fnp__vehicle-search__search__form__field__label"
                    >{{ 'FNP.VIN' | translate }}</label>
                    <input
                            lettersNumbersOnly
                            id="vinI"
                            class="fnp__vehicle-search__search__form__field__input"
                            type="text"
                            formControlName="vin"
                            [maxLength]="maxVinLength">
                    <span appFeatureFlag="searchByRegistrationNumber"
                          class="fnp__vehicle-search__search__form__field__inputs-separator"
                    >{{ 'FNP.OR' | translate }}</span>
                    <div appFeatureFlag="searchByRegistrationNumber"
                         class="fnp__vehicle-search__search__form__field__registration-number">
                        <label
                                for="registrationNumber"
                                class="fnp__vehicle-search__search__form__field__label"
                        >{{ 'FNP.REGISTRATION_NUMBER' | translate }}</label>
                        <input
                                id="registrationNumber"
                                class="fnp__vehicle-search__search__form__field__input"
                                type="text"
                                formControlName="registrationNumber"
                                [maxLength]="maxRegistrationNumberLength">
                    </div>
                </div>
                <button
                        type="submit"
                        class="btn button fnp__vehicle-search__search__form__submit"
                        [disabled]="vehicleFormGroup?.invalid"
                >{{ "FNP.APPLY" | translate }}
                </button>
                <button
                        type="button"
                        class="btn fnp__vehicle-search__search__form__reset"
                        (click)="resetPage()"
                >{{ "FNP.RESET" | translate }}
                </button>
            </form>
            <span
                    class="error-message"
                    [ngStyle]="{'visibility' : (vehicleFormError ? 'visible' : 'hidden')}"
            >{{ vehicleFormError | translate }}</span>
        </div>
        <div class="fnp__vehicle-search__files-info">
            <div class="fnp__vehicle-search__files-info__section__section">
                <span class="fnp__vehicle-search__files-info__section__part">{{ 'FNP.DOWNLOAD_FOR' | translate }}</span>
                <button
                        class="fnp__vehicle-search__files-info__section__link first"
                        (click)="downloadRepairFile()"
                >{{ "FNP.FCJ" | translate }}
                </button>
                <span class="fnp__vehicle-search__files-info__section__part">{{ "FNP.OR" | translate }}</span>
                <button
                        class="fnp__vehicle-search__files-info__section__link"
                        (click)="downloadServiceFile()"
                >{{ "FNP.SERVICE" | translate }}
                </button>
            </div>
            <div class="fnp__vehicle-search__files-info__section">
                <span class="fnp__vehicle-search__files-info__section__part">{{ "FNP.MENUS_UPDATED_AS_OF" | translate }}</span>
                <span class="fnp__vehicle-search__files-info__section__date">{{ effectiveDate ? (effectiveDate | date: 'dd/MM/yyyy') : ("GLOBAL.NOT_SET" | translate) }}</span>
            </div>
        </div>
    </div>
    <div class="fnp__vehicle-info">
        <div
                class="fnp__vehicle-info__header"
        >
            <span>{{ "FNP.VEHICLE_INFO" | translate }}</span>
        </div>
        <ng-container *ngTemplateOutlet="vehicleTableRef"></ng-container>
        <ng-template #vehicleTableRef>
            <app-vehicle-table [currentVehicle]="currentVehicle"></app-vehicle-table>
        </ng-template>
    </div>

    <div class="fnp__prices">
        <span>{{ "FNP.PRICES_SHOWN" | translate }}</span>
    </div>

    <div class="fnp__job-info">
        <div class="fnp__job-info__header">
            <span>{{ "FNP.JOB_INFORMATION" | translate }}</span>
        </div>
        <form
                class="fnp__job-info__form"
                [formGroup]="checkboxFormGroup"
                (ngSubmit)="onSubmitJobInfo()">
            <div class="fnp__job-info__form__checkbox">
                <input
                        id="serviceI"
                        type="checkbox"
                        class="form-check fnp-checkbox me-3 ms-1"
                        formControlName="service">
                <label for="serviceI"> {{ "FNP.SERVICE" | translate }}</label>
            </div>
            <div class="fnp__job-info__form__checkbox ms-3">
                <input
                        id="repairI"
                        type="checkbox"
                        class="form-check fnp-checkbox me-3 ms-1"
                        formControlName="repair">
                <label for="repairI"> {{ "FNP.REPAIR" | translate }}</label>
            </div>
            <button
                    type="submit"
                    class="btn fnp__job-info__form__submit"
                    [disabled]="!currentVehicle"
            >{{ "FNP.APPLY" | translate }}
            </button>
        </form>
        <div class="fnp__job-info__displayed">

            <div class="fnp__job-info__displayed__service-main">
                <div class="fnp__job-info__displayed__service-main__header">
                    <div
                            class="fnp__job-info__displayed__service-main__header__clickable"
                            (click)="toggleVisibility('serviceExpandable')">
            <span
                    class="fnp__job-info__displayed__service-main__header__clickable__txt">{{ "FNP.SERVICE_MAINTENANCE" | translate }}</span>
                        <fa-icon
                                class="fnp__job-info__displayed__service-main__header__clickable__icon"
                                [icon]="['fas', getArrowClass('serviceExpandable')]"
                        ></fa-icon>
                    </div>
                    <div class="fnp__job-info__displayed__service-main__header__spacer"></div>
                </div>
                <ng-container *ngTemplateOutlet="serviceTableRef"></ng-container>
                <ng-template #serviceTableRef>
                    <app-job-info-table
                        #serviceTableComponentRef
                        id="serviceExpandable"
                        class="fnp__job-info__displayed__repair__table"
                        [dataKeyword]="'service'"
                        [headers]="headers"
                        [data]="serviceData"
                        [jobErrorMsg]="jobServiceErrMsg"
                        [handleSelections$]="handleSelections$"
                        [printing]="printing"
                    ></app-job-info-table>
                </ng-template>
            </div>

            <div class="fnp__job-info__displayed__service-ext">
                <div class="fnp__job-info__displayed__service-ext__header">
                    <div
                            class="fnp__job-info__displayed__service-ext__header__clickable"
                            (click)="toggleVisibility('maintenanceExpandable')">
            <span
                    class="fnp__job-info__displayed__service-ext__header__clickable__txt">{{ "FNP.EXTENDED_MAINTENANCE" | translate }}</span>
                        <fa-icon
                                class="fnp__job-info__displayed__service-ext__header__clickable__icon"
                                [icon]="['fas', getArrowClass('maintenanceExpandable')]"
                        ></fa-icon>
                    </div>
                    <div class="fnp__job-info__displayed__service-ext__header__spacer"></div>
                </div>
                <ng-container *ngTemplateOutlet="maintenanceTableRef"></ng-container>
                <ng-template #maintenanceTableRef>
                    <app-job-info-table
                        #maintenanceTableComponentRef
                        id="maintenanceExpandable"
                        class="fnp__job-info__displayed__repair__table"
                        [dataKeyword]="'maintenance'"
                        [headers]="headers"
                        [data]="extendedServiceData"
                        [jobErrorMsg]="jobMaintenanceErrMsg"
                        [handleSelections$]="handleSelections$"
                        [printing]="printing"
                    ></app-job-info-table>
                </ng-template>
            </div>


            <div class="fnp__job-info__displayed__repair">
                <div class="fnp__job-info__displayed__repair__header">
                    <div
                            class="fnp__job-info__displayed__repair__header__clickable"
                            (click)="toggleVisibility('repairExpandable')">
            <span
                    class="fnp__job-info__displayed__repair__header__clickable__txt">{{ "FNP.REPAIR_INFO" | translate }}</span>
                        <fa-icon
                                class="fnp__job-info__displayed__repair__header__clickable__icon"
                                [icon]="['fas', getArrowClass('repairExpandable')]"
                        ></fa-icon>
                    </div>
                    <div class="fnp__job-info__displayed__repair__header__spacer"></div>
                </div>
                <ng-container *ngTemplateOutlet="repairTableRef"></ng-container>
                <ng-template #repairTableRef>
                    <app-job-info-table
                        #repairTableComponentRef
                        id="repairExpandable"
                        class="fnp__job-info__displayed__repair__table"
                        [dataKeyword]="'repair'"
                        [headers]="headers"
                        [data]="repairData"
                        [jobErrorMsg]="jobRepairErrMsg"
                        [handleSelections$]="handleSelections$"
                        [printing]="printing"
                    ></app-job-info-table>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="fnp__action">
        <button
                type="button"
                class="btn fnp__action__apply"
                [disabled]="!currentVehicle"
                (click)="filterSelected()"
        >{{ "FNP.APPLY" | translate }}
        </button>
        <button
                type="button"
                class="btn fnp__action__export"
                [disabled]="!currentVehicle"
                (click)="exportToExcel()"
        >{{ "FNP.EXPORT" | translate }}
        </button>
        <button
                type="button"
                class="btn fnp__action__reset"
                (click)="resetPage()"
        >{{ "FNP.RESET" | translate }}
        </button>
        <button
                type="button"
                class="btn fnp__action__print"
                [disabled]="!currentVehicle"
                (click)="print()"
        >{{ "FNP.PRINT" | translate }}
        </button>
    </div>
</div>
<app-fnp-print
        id="fnp-print-container"
        style="display: none"
        [useRegistrationNumber]="lastSearch === 'regNumber'"
        [currentVehicle]="currentVehicle"
        [serviceData]="serviceData"
        [extendedServiceData]="extendedServiceData"
        [repairData]="repairData"
        [dataTableArray]="[serviceTableRef, maintenanceTableRef, repairTableRef]"
        [vehicleTable]="vehicleTableRef"
></app-fnp-print>
