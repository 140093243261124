import {inject, Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {finalize, Observable} from 'rxjs';
import {SpinnerService} from '../../services/spinner.service';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
    private _spinnerService: SpinnerService = inject(SpinnerService);

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const path = this.getPath(request);
        this._spinnerService.activate(path);
        return next.handle(request).pipe(finalize(() => this._spinnerService.deactivate(path)));
    }

    private getPath(request: HttpRequest<unknown>): string {
        return `${request.method}&${request.urlWithParams}&${Date.now()}}`;
    }
}
