import {Component, Input, OnInit} from '@angular/core';
import {Vehicle} from '../../../../models/vehicle';
import {isEdgeBrowser} from '../../../../utils/other/utilities';

@Component({
  selector: 'app-vehicle-table',
  templateUrl: './vehicle-table.component.html',
  styleUrls: ['./vehicle-table.component.scss']
})
export class VehicleTableComponent implements OnInit{
    @Input() public currentVehicle: Vehicle;

    public readonly headers: string[] = [
        'FNP.BUILD_DATE',
        'FNP.MODEL',
        'FNP.BODY_STYLE',
        'FNP.ENGINE_TYPE',
        'FNP.TRANSMISSION',
        'FNP.DRIVE'
    ];
    public isEdgeBrowser: boolean;

    public ngOnInit(): void {
        this.isEdgeBrowser = isEdgeBrowser();
    }
}
