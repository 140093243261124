<div class="fnp-pagination">
    <ng-container *ngIf="pageable; then paginator; else paginatorPlaceholder"></ng-container>
    <ng-template #paginator>
        <nav class="fnp-pagination__nav" aria-label="Page navigation example">
            <ul class="pagination">
                <li class="page-item">
                    <button
                            class="btn fnp-btn-tertiary page-link fnp-pagination__nav__previous-button"
                            aria-label="Previous"
                            [disabled]="pageable.pageNumber === 1"
                            (click)="goToPreviousPage()">
                        <span aria-hidden="true">&lt;&nbsp;{{"PAGINATOR.PREVIOUS" | translate}}</span>
                    </button>
                </li>

                <div>
                    <ng-container *ngIf="pageable.pages < maxPages; then finPage; else inFinPage"></ng-container>
                </div>

                <li class="page-item">
                    <button
                            class="btn fnp-btn-tertiary page-link fnp-pagination__nav__next-button"
                            aria-label="Next"
                            [disabled]="pageable.pageNumber === pageable?.pages??1 || pageable.pages === 0"
                            (click)="goToNextPage()">
                        <span aria-hidden="true">{{"PAGINATOR.NEXT" | translate}}&nbsp;&gt;</span>
                    </button>
                </li>
            </ul>
        </nav>
    </ng-template>

    <ng-template #paginatorPlaceholder>
        <h3 class="fnp-pagination__placeholder">{{"PAGINATOR.PLACEHOLDER" | translate}}</h3>
    </ng-template>
</div>

<!-- TEMPLATE WITH ALL PAGES DISPLAYED -->
<ng-template #finPage>
    <li
            *repeat="let index times (pageable?.pages ?? 1)"
            class="page-item">
        <button
                type="button"
                class="fnp-btn-tertiary"
                [ngClass]="{'active-page': pageable.pageNumber === (index + 1)}"
                [disabled]="pageable.pageNumber === index + 1"
                (click)="goToPage(index + 1)"
        >{{index + 1}}</button>
        <span *ngIf="(index+1) < pageable?.pages??1" class="spacer">|</span>
    </li>
</ng-template>

<!-- TEMPLATE WITH HIDDEN PAGES -->
<ng-template #inFinPage>
    <li class="page-item">
        <button
                class="fnp-btn-tertiary"
                [ngClass]="{'active-page': pageable.pageNumber === 1}"
                [disabled]="pageable.pageNumber === 1"
                (click)="goToPage(1)"
        >{{1}}</button>
        <span class="spacer">|</span>
    </li>
    <li class="page-item">
        <button
                class="fnp-btn-tertiary"
                [ngClass]="{'active-page': pageable.pageNumber === 2}"
                [disabled]="pageable.pageNumber === 2"
                (click)="goToPage(2)"
        >{{2}}</button>
        <span class="spacer">|</span>
    </li>

    <ng-container *ngIf="pageable.pageNumber > 1">
        <li
                *ngIf="pageable.pageNumber > 4"
                class="page-item">
            <button
                    type="button"
                    class="fnp-btn-tertiary"
                    (click)="([pageable.pages, pageable.pages - 1, 1, 2].includes(pageable.pageNumber) ? goToMiddlePage() : goToPage(3))"
            >{{'...'}}</button>
            <span class="spacer">|</span>
        </li>
        <li
                *ngIf="pageable.pageNumber > 3 && pageable.pageNumber < (pageable.pages)"
                class="page-item">
            <button
                    class="fnp-btn-tertiary"
                    (click)="goToPage(pageable.pageNumber - 1)"
            >{{pageable.pageNumber - 1}}</button>
            <span class="spacer">|</span>
        </li>

        <li
                *ngIf="pageable.pageNumber < (pageable.pages - 1) && pageable.pageNumber > 2"
                class="page-item">
            <button
                    disabled
                    class="fnp-btn-tertiary active-page"
                    (click)="goToPage(pageable.pageNumber)"
            >{{pageable.pageNumber}}</button>
            <span class="spacer">|</span>
        </li>
        <li
                *ngIf="pageable.pageNumber < (pageable.pages-2)"
                class="page-item">
            <button
                    class="fnp-btn-tertiary"
                    (click)="goToPage(pageable.pageNumber + 1)"
            >{{pageable.pageNumber + 1}}</button>
            <span class="spacer">|</span>
        </li>
    </ng-container>

    <li
            *ngIf="pageable.pageNumber < (pageable.pages-3)"
            class="page-item">
        <button
                type="button"
                class="fnp-btn-tertiary"
                (click)="([pageable.pages, pageable.pages - 1, 1, 2].includes(pageable.pageNumber) ? goToMiddlePage() : goToPage(pageable.pages-2))"
        >{{'...'}}</button>
        <span class="spacer">|</span>
    </li>

    <li class="page-item">
        <button
                class="fnp-btn-tertiary"
                [ngClass]="{'active-page': pageable.pageNumber === (pageable.pages - 1)}"
                [disabled]="pageable.pageNumber === (pageable.pages - 1)"
                (click)="goToPage(pageable.pages - 1)"
        >{{pageable.pages - 1}}</button>
        <span class="spacer">|</span>
    </li>
    <li class="page-item">
        <button
                class="fnp-btn-tertiary"
                [ngClass]="{'active-page': pageable.pageNumber ===pageable.pages}"
                [disabled]="pageable.pageNumber === pageable.pages"
                (click)="goToPage(pageable.pages)"
        >{{pageable.pages}}</button>
    </li>

</ng-template>