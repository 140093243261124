import {inject, Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../config/environments/environment';
import {MarketService} from './market.service';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {

  private httpClient = inject(HttpClient);
  private _marketService: MarketService = inject(MarketService);


  public isNewsletterRead(email: string): Observable<boolean> {
    return this.httpClient.get<boolean>(`${environment.apiUrl}/users/${email}/newsletter-read`);
  }

  public setNewsletterRead(email: string): Observable<void> {
    return this.httpClient.patch<void>(`${environment.apiUrl}/users/${email}/newsletter-read`, true);
  }

  public getNewsletterFile(): Observable<HttpResponse<ArrayBuffer>> {
    return this.httpClient.get<ArrayBuffer>(`${environment.apiUrl}/newsletters/${this.marketId}?status=ok`,
      {responseType: 'blob' as 'json', observe: 'response'});
  }

  public get marketId(): number {
    return this._marketService.currentMarket?.id;
  }
}
