export enum REQUESTED_JOB_INFORMATION_TYPE {
    Repair = 0,
    Service = 1,
    Both = 2
}

export enum JOB_INFORMATION_TYPE_ID {
    SERVICE = 1,
    EXTENDED_SERVICE = 2,
    SMART_REPAIR = 3,
    REPAIR = 4
}