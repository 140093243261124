import {Component, Input, TemplateRef} from '@angular/core';
import {JobInformation} from '../../../../models/job-information';
import {Vehicle} from '../../../../models/vehicle';
import {JobInfoTableComponent} from '../job-info-table/job-info-table.component';
import {VehicleTableComponent} from '../vehicle-table/vehicle-table.component';

@Component({
    selector: 'app-fnp-print',
    templateUrl: './fnp-print.component.html',
    styleUrls: ['./fnp-print.component.scss']
})
export class FnpPrintComponent {
    @Input() public useRegistrationNumber: boolean;
    @Input() public currentVehicle: Vehicle;
    @Input() public serviceData: JobInformation[];
    @Input() public extendedServiceData: JobInformation[];
    @Input() public repairData: JobInformation[];
    @Input() public dataTableArray: TemplateRef<JobInfoTableComponent>[];
    @Input() public vehicleTable: TemplateRef<VehicleTableComponent>;
}
