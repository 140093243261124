import {Component, EventEmitter, inject, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {minDateValidator} from '../../../../../utils/validators/min-date-validator';
import {UploadFnpFile} from '../../../../../models/fnp-file';
import {DialogService} from '../../../../../services/dialog.service';

@Component({
    selector: 'app-upload-file',
    templateUrl: './upload-file.component.html',
    styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {

    @ViewChild('fileModalTemplate', {static: true}) fileModalTemplateRef: TemplateRef<any>;

    @Input() public acceptedTypes: string;
    @Input() public errorMessage?: { message: string };
    @Input() public openModal$: EventEmitter<void>;
    @Output() public uploadFile$: EventEmitter<UploadFnpFile> = new EventEmitter<UploadFnpFile>();

    public fileFormGroup: FormGroup;
    private currentlyUploadedFile?: File;

    private _formBuilder: FormBuilder = inject(FormBuilder);
    private _modalService: NgbModal = inject(NgbModal);
    private _dialogService: DialogService = inject(DialogService);

    public ngOnInit(): void {
        this.openModal$.subscribe(() => this.openUploadModal());
    }

    public openUploadModal(): void {
        this.fileFormGroup = this._formBuilder.group({
                file: ['', [Validators.required]],
                fileName: ['', [Validators.required]],
                publicationDate: ['', [Validators.required]]
            },
            {validators: minDateValidator()});
        this._modalService.open(this.fileModalTemplateRef, {backdrop: 'static', centered: true, keyboard: true});
    }

    public flushModalData(): void {
        delete this.errorMessage;
        delete this.fileFormGroup;
    }

    public updateCurrentFileName(fileEvent: any): void {
        if (fileEvent && fileEvent?.target?.files[0].name.endsWith(this.acceptedTypes)) {
            this.currentlyUploadedFile = fileEvent?.target?.files[0];
            this.fileFormGroup.get('fileName').patchValue(fileEvent?.target?.files[0]?.name);
        } else {
            const dialogContent = this._dialogService.getNewDialogContent('error', 'ADMIN.WRONG_FILE_EXTENSION',
                null, 'ADMIN.UPLOAD_FILE_IN_REQUIRED_FORMAT', this.acceptedTypes.replace('.', ''));
            this._dialogService.openInfoDialog(dialogContent);
        }

    }

    public uploadFile() {
        const publicationDateTimestamp = Date.UTC(this.publicationDateCtrlValue?.year, this.publicationDateCtrlValue?.month - 1, this.publicationDateCtrlValue?.day) / 1000;
        const uploadFile: UploadFnpFile = {file: this.currentlyUploadedFile, publicationDateTimestamp};
        this.uploadFile$.emit(uploadFile);
    }

    public get publicationDateCtrl(): AbstractControl {
        return this.fileFormGroup?.get('publicationDate');
    }

    public get publicationDateCtrlValue(): NgbDateStruct {
        return this.fileFormGroup.get('publicationDate').value;
    }

}
