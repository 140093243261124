<div class="my-details">
    <div class="my-details__form">
        <div class="my-details__form__header">
            <h2>{{'MY_DETAILS.MY_DETAILS' | translate}}</h2>
        </div>
        <div class="my-details__form__fields">
            <ng-container
                    *ngIf="editMode === 'user'; then userFieldsTemplate; else passwordFieldsTemplate"></ng-container>
            <ng-template #userFieldsTemplate>
                <form
                        *ngIf="userFormGroup"
                        [formGroup]="userFormGroup">
                    <div class="my-details__form__fields__field">
                        <label
                                for="finCodeI"
                                class="my-details__form__fields__field__label"
                        >{{'MY_DETAILS.MCO_FIN_CODE' | translate}}</label>
                        <input
                                readonly
                                id="finCodeI"
                                class="form-control my-details__form__fields__field__input input--disabled"
                                formControlName="finCode">
                    </div>
                    <div class="my-details__form__fields__field">
                        <label
                                for="companyNameI"
                                class="my-details__form__fields__field__label"
                        >{{'MY_DETAILS.COMPANY_NAME' | translate}}</label>
                        <input
                                readonly
                                id="companyNameI"
                                class="form-control my-details__form__fields__field__input input--disabled"
                                formControlName="companyName">
                    </div>
                    <div class="my-details__form__fields__field">
                        <label
                                for="emailAddressI"
                                class="my-details__form__fields__field__label"
                        >{{'MY_DETAILS.EMAIL_ADDRESS' | translate}}</label>
                        <input
                                readonly
                                id="emailAddressI"
                                class="form-control my-details__form__fields__field__input input--disabled"
                                formControlName="email">
                    </div>
                    <div class="my-details__form__fields__field">
                        <label
                                for="contactNameI"
                                class="my-details__form__fields__field__label"
                        >{{'MY_DETAILS.CONTACT_NAME' | translate}}</label>
                        <input
                                id="contactNameI"
                                class="form-control my-details__form__fields__field__input"
                                formControlName="contactName"
                                [readOnly]="!isEditActive"
                                [ngClass]="isEditActive ? '' : 'input--disabled'">
                    </div>
                </form>
            </ng-template>

            <ng-template #passwordFieldsTemplate>
                <form [formGroup]="passwordFormGroup">
                    <div class="my-details__form__fields__field">
                        <label
                                for="currentPasswordI"
                                class="my-details__form__fields__field__label"
                        >{{'MY_DETAILS.CURRENT_PASSWORD' | translate}}</label>
                        <app-password-input
                                id="currentPasswordI"
                                class="form-control my-details__form__fields__field__input"
                                formControlName="currentPassword"></app-password-input>
                    </div>
                    <div class="my-details__form__fields__field">
                        <label
                                for="newPasswordI"
                                class="my-details__form__fields__field__label"
                        >{{'MY_DETAILS.NEW_PASSWORD' | translate}}</label>
                        <app-password-input
                                id="newPasswordI"
                                class="form-control my-details__form__fields__field__input"
                                formControlName="newPassword"
                        ></app-password-input>
                    </div>
                    <div class="my-details__form__fields__field">
                        <label
                                for="confirmPasswordI"
                                class="my-details__form__fields__field__label"
                        >{{'MY_DETAILS.CONFIRM_PASSWORD' | translate}}</label>
                        <app-password-input
                                id="confirmPasswordI"
                                class="form-control my-details__form__fields__field__input"
                                [ngClass]="{'invalid-input': passwordFormGroup?.hasError('passwordsDoNotMatch')
                        && (newPasswordCtrl.touched || newPasswordCtrl.dirty)
                        && (confirmPasswordCtrl.touched || confirmPasswordCtrl.dirty)}"
                                formControlName="confirmPassword"></app-password-input>
                    </div>
                </form>
            </ng-template>
        </div>
        <span
                *ngIf="editMode === 'password' && passwordFormGroup?.hasError('passwordsDoNotMatch')
                        && (newPasswordCtrl.touched || newPasswordCtrl.dirty)
                        && (confirmPasswordCtrl.touched || confirmPasswordCtrl.dirty)"
                class="error-message"
        >{{"MY_DETAILS.PASSWORDS_DO_NOT_MATCH" | translate}}</span>

        <div class="my-details__form__buttons">
            <ng-container *ngIf="isEditActive; then editTemplate; else displayTemplate"></ng-container>
            <ng-template #displayTemplate>
                <div class="my-details__form__buttons__action">
                    <button
                            class="btn"
                            (click)="toggleEdit()"
                    >{{'MY_DETAILS.EDIT' | translate}}</button>
                </div>
            </ng-template>
            <ng-template #editTemplate>
                <div class="my-details__form__buttons__toggle">
                    <button
                            *ngIf="editMode === 'user'"
                            class="btn"
                            (click)="setPasswordEdit()"
                    >{{'MY_DETAILS.SET_NEW_PASSWORD' | translate}}</button>
                </div>
                <div class="my-details__form__buttons__action">
                    <button
                            class="btn my-details__form__buttons__action__back"
                            (click)="editMode === 'password' ? setUserEdit() : toggleEdit()"
                    >{{'GLOBAL.BACK' | translate}}</button>
                    <button
                            class="btn"
                            [disabled]="(editMode === 'user' && userFormGroup.invalid) || (editMode === 'password' && passwordFormGroup.invalid)"
                            (click)="editMode === 'password' ? submitPassword() : submitUser()"
                    >{{'GLOBAL.SAVE' | translate}}</button>
                </div>
            </ng-template>
        </div>
    </div>
</div>