import {CanActivateFn} from '@angular/router';
import {inject} from '@angular/core';
import {RouteService} from 'src/app/services/route.service';

export const defaultRouteGuard: CanActivateFn = () => {
    const _routeService: RouteService = inject(RouteService);
    if (_routeService.checkRoute()) {
        return true;
    } else {
        _routeService.navigateToDefault();
        return false;
    }
};
