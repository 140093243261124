<footer class="fnp-footer">
    <div class="fnp-footer__rights">
        <span>{{ "FOOTER.OEC_ALL_RIGHTS_RESERVED" | translate }}</span>
    </div>
    <div class="fnp-footer__links">
        <a
                *ngIf="!isCurrentRouteHelpDesk"
                href="{{isCurrentlyLogged ? getEmailUrl((currentMarket | async)?.helpDesk, ('FOOTER.HELP_DESK_EMAIL_SUBJECT_FOR_LOGGED' | translate))
                : '/help-desk?lang=' + (currentLanguage | async) }}"
                target="{{isCurrentlyLogged ?  '_blank' : '_self'}}">{{ "FOOTER.HELP_DESK" | translate }}</a>
        <span *ngIf="!isCurrentRoutePrivacyPolicy && !isCurrentRouteHelpDesk" class="fnp-footer__links__spacer">|</span>
        <a
                *ngIf="!isCurrentRoutePrivacyPolicy"
                href="{{isCurrentlyLogged ? (currentMarket | async)?.privacyPolicyUrl : '/privacy-policy?lang=' + (currentLanguage | async)}}"
                target="{{isCurrentlyLogged ?  '_blank' : '_self'}}"
        >{{ "FOOTER.PRIVACY_POLICY" | translate }}</a>
    </div>
</footer>