import {AVAILABLE_MARKETS_ENUM} from './available-markets';

export const FEATURE_FLAG_MAP: Map<string, number[]> = new Map<string, number[]>([
    [
        'registrationMarketSelect',
        [
            AVAILABLE_MARKETS_ENUM.AUSTRIA,
            AVAILABLE_MARKETS_ENUM.SWITZERLAND,
            AVAILABLE_MARKETS_ENUM.UNITED_KINGDOM
        ]
    ],
    ['introAndMapUk',
        [
            AVAILABLE_MARKETS_ENUM.UNITED_KINGDOM
        ]
    ],
    ['searchByRegistrationNumber',
        [
            AVAILABLE_MARKETS_ENUM.UNITED_KINGDOM
        ]
    ],
    ['importUsers',
        [
            AVAILABLE_MARKETS_ENUM.UNITED_KINGDOM
        ]
    ]
]);
