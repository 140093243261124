import {CellStyle, ExcelDataForm, ExcelWorksheetForm} from '../excel';
import {WorksheetBuilder} from './worksheet-builder';
import {HeaderBuilder} from './header-builder';
import {downloadFileAction} from '../../other/utilities';
import {CurrencyPipe} from '../../pipes/currency.pipe';
import * as Excel from 'exceljs/dist/exceljs.min.js';

export class ExcelBuilder {
    private fileName?: string;
    private readonly worksheetData?: ExcelWorksheetForm[];
    private readonly worksheetBuilder: WorksheetBuilder;

    constructor() {
        this.worksheetBuilder = new WorksheetBuilder();
        this.worksheetData = [];
    }

    public setName(fileName: string): ExcelBuilder {
        this.fileName = fileName;
        return this;
    }

    public addExcelWorksheet(worksheet: ExcelWorksheetForm): ExcelBuilder {
        this.worksheetData.push(worksheet);
        return this;
    }

    public build(): ExcelDataForm {
        if (!this.fileName || !this.worksheetData) {
            throw 'Build failed. Missing properties to create Workbook.';
        }
        return {fileName: this.fileName, worksheetData: this.worksheetData};
    }

    public print(): void {
        const workbook = new Excel.Workbook();
        this.worksheetData.forEach((worksheet) => {
            this.addWorksheetToWorkbook(workbook, worksheet);
        });
        workbook.xlsx.writeBuffer().then((data: any) => {
            const blob = new Blob([data], {
                type:
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            downloadFileAction(blob, this.fileName ?? 'Report_FNP');
        });
    }

    private getHeaders(o: NonNullable<unknown>): Excel.Column[] {
        return Object.getOwnPropertyNames(o).map<Excel.Column>((name) => {
            return {header: name, key: name, width: name.length + 20};
        });
    }

    private addWorksheetToWorkbook(workbook: Excel.Workbook, worksheetForm: ExcelWorksheetForm): void {
        const ws = workbook.addWorksheet(worksheetForm.worksheetName);
        ws.columns = worksheetForm.headers ?? this.getHeaders(worksheetForm.rows[0]);
        worksheetForm.rows.forEach((row) => ws.addRow(row));
        if(worksheetForm.rows.length === 0) ws.addRow([null, worksheetForm.placeholder]);

        this.addInsertedRows(ws, worksheetForm);
        this.applyHeaderStyles(ws, worksheetForm);
        this.adjustCellWidth(ws);
        this.addCurrencySymbol(ws, worksheetForm);
    }

    private addInsertedRows(ws: Excel.Worksheet, worksheetForm: ExcelWorksheetForm): void {
        worksheetForm.insertedRows.forEach((row) => {
            ws.insertRow(row.position, row.values);
            if (row.outlineStyle) {
                if (row.values?.length > 1) {
                    (row.values as []).forEach((v, i) => ws.getCell(`${String.fromCharCode(i + 65)}${row.position}`).border = this.getBorderStyles(row.outlineStyle));
                } else {
                    ws.getCell(`A${row.position}`).border = this.getBorderStyles(row.outlineStyle);
                }
            }
            if (row.fontStyles) {
                (row.values as []).forEach((v, i) => ws.getCell(`${String.fromCharCode(i + 65)}${row.position}`).font = this.getExcelStyleObject(row.fontStyles));
            }
        });
    }

    private applyHeaderStyles(ws: Excel.Worksheet, worksheetForm: ExcelWorksheetForm): void {
        if (worksheetForm.outlineHeaderStyle) {
            const colIdx = this.countHeaderRow(worksheetForm);
            worksheetForm.headers.forEach((v, i) => {
                const cellRef = ws.getCell(`${String.fromCharCode(i + 65)}${colIdx}`);
                cellRef.border = this.getBorderStyles(worksheetForm.outlineHeaderStyle);
                if (worksheetForm.headerStyles) {
                    cellRef.font = this.getExcelStyleObject(worksheetForm.headerStyles);
                }
            });
        }
    }

    private adjustCellWidth(ws: Excel.Worksheet): void {
        ws.columns.forEach(function (column) {
            let maxLength = 0;
            column['eachCell']({includeEmpty: true}, function (cell) {
                const columnLength = cell.value ? cell.value.toString().length : 10;
                if (columnLength > maxLength) {
                    maxLength = columnLength;
                }
            });
            column.width = maxLength < 15 ? 15 : maxLength + 5;
        });
    }

    private addCurrencySymbol(ws: Excel.Worksheet, worksheetForm: ExcelWorksheetForm): void {
        if (worksheetForm.priceColumns && worksheetForm.priceColumns?.length > 0 && worksheetForm.currency) {
            const colIdx = this.countHeaderRow(worksheetForm);
            const colArr = this.getPriceColumns(worksheetForm);
            const currencyPipe: CurrencyPipe = new CurrencyPipe();
            colArr.forEach((colLetter) => {
                for (let i = colIdx + 1; i < worksheetForm.rows.length + colIdx + 1; i++) {
                    const colRef = ws.getCell(`${colLetter}${i}`);
                    colRef.value = currencyPipe.transform(colRef.value, worksheetForm.currency);
                }
            });
        }
    }

    private countHeaderRow(worksheetForm: ExcelWorksheetForm): number {
        let colIdx = 0;
        worksheetForm.insertedRows.forEach((row) => {
            colIdx++;
            if (row.blankBefore) colIdx++;
            if (row.blankAfter) colIdx++;
        });
        return colIdx;
    }

    private getPriceColumns(worksheetForm: ExcelWorksheetForm): string[] {
        const colArr = [];
        worksheetForm.headers.forEach((v, i) => {
            if (worksheetForm.priceColumns.includes(v.key)) {
                colArr.push(String.fromCharCode(i + 65));
            }
        });
        return colArr;
    }

    private getExcelStyleObject(styles: CellStyle[]): { [a: string]: any }[] {
        const styleObj: any = {};
        styles.forEach((entry) => styleObj[entry.prop] = entry.val);
        return styleObj;
    }

    private getBorderStyles(style: string): any {
        return {
            top: {style: style},
            bottom: {style: style},
            left: {style: style},
            right: {style: style}
        };
    }

    public getWorksheetBuilder(): WorksheetBuilder {
        return this.worksheetBuilder;
    }

    public static getHeaderBuilder(): HeaderBuilder {
        return new HeaderBuilder();
    }

}
