import {FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';

// checks if any form field is filled/checked
export function isAnyFilledValidator(): ValidatorFn {
    return (form: FormGroup): ValidationErrors | null => {
        const properValues = Object.keys(form.controls).filter(key =>
            form.controls[key].value
        );
        return properValues.length == 0 ? {noneFilled: true} : null;
    };
}