<div class="admin__tables__table-group">
    <div class="admin__tables__table-group__prev">
        <h3>{{tableTitle | translate}}</h3>
        <button
                type="button"
                class="fnp-btn-primary btn"
                [disabled]="addDisabledState"
                (click)="openUploadModal()"
        >{{"ADMIN.ADD_NEW_FILE" | translate}}</button>

    </div>
    <div class="admin__tables__table-group__table">
        <table class="table fnp-stand-tbl">
            <thead>
            <tr>
                <th class="cell-width--10 text-align--center wrap-n">{{"ADMIN.ID" | translate}}</th>
                <th class="cell-width--40 text-align--start wrap-y">{{"ADMIN.FILE_NAME" | translate}}</th>
                <th class="cell-width--20 text-align--center wrap-n">{{"ADMIN.PUBLICATION_DATE" | translate}}</th>
                <th class="cell-width--20 text-align--center wrap-n">{{"ADMIN.STATUS" | translate}}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let fnpFile of fileData; let index=index">
                <td class="cell-width--10 text-align--center wrap-n">{{index + 1}}</td>
                <td class="cell-width--40 text-align--start wrap-y">{{fnpFile.fileName}}</td>
                <td class="cell-width--20 text-align--center">
                    <ng-container
                            *ngIf="isFileWaiting(fnpFile.status); then changeDateTemplate; else showDateTemplate"></ng-container>
                    <ng-template #showDateTemplate>
                        {{fnpFile.publicationDate | date: 'dd/MM/YYY'}}
                    </ng-template>
                    <ng-template #changeDateTemplate>
                        <div class="admin__tables__table-group__table__datepicker input-group">
                            <input
                                    id="{{'datepicker_'+ fnpFile.fileName}}"
                                    ngbDatepicker
                                    readonly
                                    #datepickerInput="ngbDatepicker"
                                    class="admin__tables__table-group__table__datepicker__input form-control"
                                    placeholder="dd-MM-yyyy"
                                    dataformatas="dd-MM-yyyy"
                                    [value]="getDatepickerStruct(fnpFile.publicationDate)"
                                    (dateSelect)="updatePublicationDate(fnpFile, $event)"/>
                            <button
                                    type="button"
                                    class="btn btn-outline-secondary admin__tables__table-group__table__datepicker__button"
                                    (click)="datepickerInput.toggle()">
                                <fa-icon class="admin__tables__table-group__table__datepicker__button__icon"
                                         [icon]="['far', 'calendar-days']"></fa-icon>
                            </button>
                        </div>
                    </ng-template>
                </td>
                <td class="cell-width--20 text-align--center">
                    <span>{{('ADMIN.' + fnpFile.status | uppercase) | translate}}</span>
                    <button
                            *ngIf="isFileWaiting(fnpFile.status)"
                            type="button"
                            class="btn cstm-icon-padding"
                            (click)="deleteFile(fnpFile)">
                        <fa-icon [icon]="['far', 'trash-can']"></fa-icon>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>

<app-upload-file
        [acceptedTypes]="acceptedTypes"
        [errorMessage]="errorMessage"
        [openModal$]="openModal$"
        (uploadFile$)="uploadFile($event)"
></app-upload-file>

<ng-template #deleteModalTemplate>
    <app-fnp-modal
            [title]="'ADMIN.DELETE'"
            [contentTemplate]="deleteConfirmationTemplate"
            [saveAction]="true"
            [modalError]="errorMessage"
            [cancelButtonLabel]="'GLOBAL.NO'"
            [submitButtonLabel]="'GLOBAL.YES'"
            (submitAction$)="performDeleteFile()"
            (cancelAction$)="flushFileToBeDeleted()"
    ></app-fnp-modal>
</ng-template>

<ng-template #deleteConfirmationTemplate>
    <div class="delete-modal">
        <div class="delete-modal__icon">
            <fa-icon [icon]="['far', 'trash-can']"></fa-icon>
        </div>
        <div class="delete-modal__txt">
            <p>{{'ADMIN.DELETE_CONFIRMATION' | translate}}</p>
        </div>
    </div>
</ng-template>
