import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Pageable} from '../../../../models/pageable';

@Component({
    selector: 'app-fnp-paginator',
    templateUrl: './fnp-paginator.component.html',
    styleUrls: ['./fnp-paginator.component.scss']
})
export class FnpPaginatorComponent {

    @Input()
    public pageable: Pageable;
    @Input()
    public maxPages = 10;
    @Output()
    public reloadPage$: EventEmitter<void> = new EventEmitter<void>();

    public goToPage(pageNumber: number): void {
        this.pageable.pageNumber = pageNumber;
        this.reloadPage$.emit();
    }

    public goToMiddlePage(): void {
        this.pageable.pageNumber = Math.floor(this.pageable.pages / 2);
        this.reloadPage$.emit();
    }

    public goToPreviousPage(): void {
        this.pageable.pageNumber--;
        this.reloadPage$.emit();
    }

    public goToNextPage(): void {
        this.pageable.pageNumber++;
        this.reloadPage$.emit();
    }
}
