import {FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

// form controls must follow naming: 'from', 'to'
export function dateRangeValidator(): ValidatorFn {
    return (form: FormGroup): ValidationErrors | null => {
        const fromDateStruct: NgbDateStruct = form.get('from').value;
        const fromDateValue = new Date(fromDateStruct.year, fromDateStruct.month - 1, fromDateStruct.day);
        const toDateStruct: NgbDateStruct = form.get('to').value;
        const toDateValue = new Date(toDateStruct.year, toDateStruct.month - 1, toDateStruct.day);
        return fromDateValue > toDateValue ? {pastDate: true} : null;
    };
}