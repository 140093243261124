import {Component, EventEmitter, inject, Input, OnInit} from '@angular/core';
import {JobInformation} from '../../../../models/job-information';
import {isEdgeBrowser} from '../../../../utils/other/utilities';
import {MarketService} from '../../../../services/market.service';
import {MarketCurrency} from '../../../../models/market';

@Component({
    selector: 'app-job-info-table',
    templateUrl: './job-info-table.component.html',
    styleUrls: ['./job-info-table.component.scss']
})
export class JobInfoTableComponent implements OnInit {
    @Input() public dataKeyword: string;
    @Input() public data: JobInformation[];
    @Input() public headers: string[];
    @Input() public jobErrorMsg?: string;
    @Input() public handleSelections$: EventEmitter<'filter' | 'clear'>;
    @Input() public printing: boolean;

    public checkboxSet: Set<string> = new Set<string>();
    public lastSelectionVal?: 'filter' | 'clear';
    public isEdgeBrowser: boolean;
    public currency: MarketCurrency;

    private _marketService: MarketService = inject(MarketService);

    public ngOnInit(): void {
        this.isEdgeBrowser = isEdgeBrowser();
        this.initMarket();
        this.handleSelections$.subscribe((val) => {
            this.lastSelectionVal = val;
            if (val === 'filter') {
                this.filterSelected();
            } else {
                this.toggleOffAll();
            }
        });
    }

    private initMarket(): void {
        this.currency = this._marketService.currency;
        this._marketService.stateUpdate.subscribe({
            next: () => this.currency = this._marketService.currency
        });
    }

    public toggleOffAll(): void {
        if (document.getElementsByName('toggleAllCheckbox_' + this.dataKeyword)[0] as HTMLInputElement) {
            (document.getElementsByName('toggleAllCheckbox_' + this.dataKeyword)[0] as HTMLInputElement).checked = false;
        }
    }

    public toggleAll(): void {
        const toggleVal: boolean = (document.getElementsByName('toggleAllCheckbox_' + this.dataKeyword)[0] as HTMLInputElement).checked;
        if (!toggleVal) this.checkboxSet.clear();
        this.data.forEach(record => {
            if (toggleVal) this.checkboxSet.add(record.fcjMenuNumber);
            (document.getElementsByName(`checkbox_${this.dataKeyword}_${record.fcjMenuNumber}`)[0] as HTMLInputElement).checked = toggleVal;
        });
    }

    public toggleSingle(fcjNumber: string): void {
        const toggleVal: boolean = (document.getElementsByName(`checkbox_${this.dataKeyword}_${fcjNumber}`)[0] as HTMLInputElement).checked;
        if (!toggleVal) (document.getElementsByName('toggleAllCheckbox_' + this.dataKeyword)[0] as HTMLInputElement).checked = false;
        toggleVal ? this.checkboxSet.add(fcjNumber) : this.checkboxSet.delete(fcjNumber);
    }

    public filterSelected(): void {
        this.data = this.data.filter(record =>
            (document.getElementsByName(`checkbox_${this.dataKeyword}_${record.fcjMenuNumber}`)[0] as HTMLInputElement).checked);
        this.toggleOffAll();
    }

    public isAnyRecordSelected(): boolean {
        for (const record of this.data) {
            if ((document.getElementsByName(`checkbox_${this.dataKeyword}_${record.fcjMenuNumber}`)[0] as HTMLInputElement)?.checked) {
                return true;
            }
        }
        return false;
    }


}
