import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../config/environments/environment';
import {Company} from '../models/company';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  private _httpClient: HttpClient = inject(HttpClient);

  public postCompany(company: Company): Observable<Company> {
    return this._httpClient.post<Company>(environment.apiUrl + '/companies', company);
  }
}
