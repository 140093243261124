import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
    selector: '[lettersNumbersOnly]',
    standalone: true
})
export class LettersNumbersInputDirective {
    private regexExpression = new RegExp('^[a-zA-Z0-9]*$');

    constructor(private el: ElementRef) {
    }

    @HostListener('keydown', ['$event']) onKeyDown(event) {
        if (this.regexExpression.test(event.key) || SPECIAL_KEY.includes(event.key)) {
            return;
        }
        event.preventDefault();
    }

    @HostListener('paste', ['$event']) onPaste(e: ClipboardEvent) {
        if (this.regexExpression.test(e.clipboardData.getData('text/plain'))) {
            return;
        }
        e.preventDefault();
    }

}

const SPECIAL_KEY = [
    'ArrowDown',
    'ArrowUp',
    'ArrowLeft',
    'ArrowRight',
    'Backspace'
];
