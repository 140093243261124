import {Directive, inject, Injector, OnDestroy, OnInit} from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    FormControlDirective,
    FormControlName,
    FormGroup,
    NG_VALUE_ACCESSOR,
    NgControl,
    NgModel
} from '@angular/forms';
import {Subscription} from 'rxjs';

@Directive({
    selector: '[appFnpFormControl]',
    standalone: true,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: FnpFormControlDirective
        }
    ]
})
export class FnpFormControlDirective implements OnInit, OnDestroy, ControlValueAccessor {
    public control: FormControl;

    private valueSubscription$: Subscription;
    private _injector: Injector = inject(Injector);

    public ngOnInit(): void {
        this.setFormControl();
    }

    private setFormControl(): void {
        const ngControl = this._injector.get(NgControl, null, {self: true, optional: true});

        if (ngControl instanceof FormControlName) {
            const container = this._injector.get(ControlContainer).control as FormGroup;
            this.control = container.controls[ngControl.name] as FormControl;
        } else if (ngControl instanceof FormControlDirective) {
            this.control = ngControl.control;
        } else if (ngControl instanceof NgModel) {
            this.control = ngControl.control;
            ngControl.control.valueChanges.subscribe((value) => {
                if (ngControl.model !== value || ngControl.viewModel !== value) {
                    ngControl.viewToModelUpdate(value);
                }
            });
        } else {
            this.control = new FormControl();
        }
    }


    // Empty functions required by CVA interface.
    // eslint-disable-next-line  @typescript-eslint/no-empty-function
    public writeValue(): void {
    }

    // eslint-disable-next-line  @typescript-eslint/no-empty-function
    public registerOnChange(): void {
    }

    // eslint-disable-next-line  @typescript-eslint/no-empty-function
    public registerOnTouched(): void {
    }

    public ngOnDestroy(): void {
        this.valueSubscription$?.unsubscribe();
    }
}
