import {Pipe, PipeTransform} from '@angular/core';
import {MarketCurrency} from '../../models/market';

@Pipe({
    name: 'currency'
})
export class CurrencyPipe implements PipeTransform {

    transform(value: string | number, currency: MarketCurrency): string {
        return currency.position === 'post' ? `${value} ${currency.symbol}` : `${currency.symbol}${value}`;

    }

}
