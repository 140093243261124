import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {FnpDialogContent} from '../views/shared/fnp-dialog/fnp-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class DialogService {

    public dialogState$: BehaviorSubject<FnpDialogContent | null> = new BehaviorSubject<FnpDialogContent | null>(null);

    public getNewDialogContent(icon: 'success' | 'warning' | 'error', title?: string, buttonLabel?: string, text1?: string,
                               text2?: string, text3?: string): FnpDialogContent {
        return {
            icon,
            title: title ? title : icon === 'success' ? 'GLOBAL.SUCCESS' : icon === 'warning' ? 'GLOBAL.WARNING' : 'GLOBAL.ERROR',
            buttonLabel: buttonLabel ?? 'GLOBAL.CLOSE',
            text1,
            text2,
            text3
        };
    }

    //timeout must be provided in milliseconds
    public openInfoDialog(dialogContent: FnpDialogContent, timeout = false, timeoutTime?: number): void {
        this.dialogState$.next(dialogContent);
        if (timeout) {
            setTimeout(() => this.closeAllDialogs(), timeoutTime ?? 3000);
        }
    }

    public closeAllDialogs(): void {
        this.dialogState$.next(null);
    }
}
