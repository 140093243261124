<div class="multimarket">
    <div class="multimarket__header">
        <h2>{{title | translate}}</h2>
    </div>
    <div class="multimarket__flags">
        <a
                *ngFor="let market of availableMarkets"
                class="multimarket__flags__flag"
                target="_blank"
                [href]="linkType === 'link' ? market[marketField] : 'mailto:' + market[marketField] + '?subject=' + ('FOOTER.HELP_DESK_EMAIL_SUBJECT_FOR_NOT_LOGGED' | translate)"
                [ngStyle]="{'width': getStyle()}"
        >
            <img
                    [id]="market.shortname + 'Flag'"
                    [src]="'assets/img/'+ market.shortname!.toLowerCase() +'-flag.png'"
                    [alt]="market.name + ' logo'">
            <label [for]="market.shortname + 'Flag'">{{('GLOBAL.' + market.name.replace(' ','_').toUpperCase())  | translate}}</label>
        </a>
    </div>
    <div class="multimarket__action">
        <button
                type="button"
                class="fnp-btn-secondary btn"
                (click)="back()"
        >{{"GLOBAL.BACK" | translate}}</button>
    </div>
</div>