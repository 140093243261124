<ng-template #fileModalTemplate>
    <app-fnp-modal
            [title]="'ADMIN.ADD_NEW'"
            [contentTemplate]="uploadModalContentTemplate"
            [saveAction]="true"
            [invalidForm]="fileFormGroup?.invalid"
            [modalError]="errorMessage"
            (submitAction$)="uploadFile()"
            (cancelAction$)="flushModalData()"
    ></app-fnp-modal>
</ng-template>

<ng-template #uploadModalContentTemplate>
    <form
            *ngIf="fileFormGroup"
            class="upload-modal"
            [formGroup]="fileFormGroup">
        <div class="upload-modal__publication-date">
            <label
                    for="publicationDateI"
                    class="upload-modal__publication-date__label"
            >{{'ADMIN.PUBLICATION_DATE' | translate}}</label>
            <div class="input-group">
                <input
                        ngbDatepicker
                        readonly
                        #datepickerInput="ngbDatepicker"
                        id="publicationDateI"
                        name="dp"
                        class="upload-modal__publication-date__input form-control"
                        placeholder="dd-MM-yyyy"
                        dataformatas="dd-MM-yyyy"
                        formControlName="publicationDate"
                        />
                <button
                        type="button"
                        class="btn btn-outline-secondary upload-modal__publication-date__button"
                        (click)="datepickerInput.toggle()">
                    <fa-icon [icon]="['far', 'calendar-days']"></fa-icon>
                </button>
            </div>
        </div>
        <div class="upload-modal__file-input">
            <input
                    readonly
                    class="form-control upload-modal__file-input__file-name"
                    formControlName="fileName"
                    [placeholder]="'ADMIN.NO_FILE_SELECTED' | translate">
            <button
                    type="button"
                    class="fnp-btn-primary upload-modal__file-input__upload-button"
                    (click)="serviceFileInput.click()"
            >{{"ADMIN.BROWSE_FILES" | translate}}</button>
            <input
                    hidden
                    #serviceFileInput
                    type="file"
                    formControlName="file"
                    accept="{{acceptedTypes}}"
                    (change)="updateCurrentFileName($event)"/>
        </div>
    </form>
    <span
            *ngIf="fileFormGroup?.hasError('pastDate')
                        && (publicationDateCtrl.touched || publicationDateCtrl.dirty)"
            class="error-message"
    >{{"ADMIN.DATE_CAN_NOT_BE_IN_THE_PAST" | translate}}</span>
</ng-template>
