import {Observable, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';

export class MultiRequest<T> {
    private readonly _dataIterator: Iterator<T>;
    private readonly _requestMethod: (payload: T) => Observable<T>;
    private readonly positiveResults: T[];
    private readonly negativeResults: T[];
    private readonly _return$: Subject<{ positiveResults: T[], negativeResults: T[] }>;

    private readonly _httpClient: HttpClient;

    constructor(private readonly data: T[], requestMethod: (payload: T) => Observable<T>, httpClient: HttpClient) {
        this._dataIterator = data[Symbol.iterator]();
        this._requestMethod = requestMethod;
        this._httpClient = httpClient;
        this.positiveResults = [];
        this.negativeResults = [];
        this._return$ = new Subject();
    }

    public call(): Subject<{ positiveResults: T[]; negativeResults: T[] }> {
        this.proceedCall();
        return this._return$;
    }

    private proceedCall(): void {
        const tempObj: IteratorResult<T> = this._dataIterator.next();
        try {
            if (tempObj.value) {
                this._requestMethod(tempObj.value).subscribe({
                    next: () => {
                        this.positiveResults.push(tempObj.value);
                        this.proceedCall();
                    },
                    error: () => {
                        this.negativeResults.push(tempObj.value);
                        this.proceedCall();
                    }
                });
            } else {
                this._return$.next({
                    positiveResults: this.positiveResults,
                    negativeResults: this.negativeResults
                });
            }
        } catch (e) {
            this._return$.error(e);
        }
    }

}
