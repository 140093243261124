import {FilterParam, SearchCriteria, Sortable} from '../../models/search-criteria';
import {environment} from '../../../config/environments/environment';
import {Pageable} from '../../models/pageable';

export class UrlBuilder {

    public static buildUrl(path: string, searchCriteria: SearchCriteria): string {
        let url = environment.apiUrl + path + '?';
        url = this.applySort(url, searchCriteria.sortBy);
        url = this.applyFiltering(url, searchCriteria.filters);
        url = this.applyPagination(url, searchCriteria.pageable);
        return url;
    }

    public static applySort(url: string, sortable: Sortable): string {
        if (sortable) url += `sortColumn=${sortable.sortColumn}&sortType=${sortable.sortType}&`;
        return url;
    }

    public static applyFiltering(url: string, filters: FilterParam[]): string {
        filters.forEach(filter => {
            url += `${filter.filteringField}=${filter.filteringValue}&`;
        });
        return url;
    }

    public static applyPagination(url: string, pageable: Pageable): string {
        url += `page=${pageable.pageNumber - 1}`;
        return url;
    }
}