import {inject, Injectable, NgZone} from '@angular/core';
import {StorageService} from './storage.service';
import {AuthService} from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class AutoLogoutService {

    private readonly MINUTES_UNTIL_AUTO_LOGOUT = 30;
    private readonly CHECK_INTERVALL = 3000;
    private readonly STORE_KEY = 'lastAction';

    private _ngZone: NgZone = inject(NgZone);
    private _authService: AuthService = inject(AuthService);
    private _storageService: StorageService = inject(StorageService);

    constructor() {
        this.initListener();
        this.reset();
        this.initInterval();
    }

    public get lastAction(): number {
        return parseInt(this._storageService.getStored(this.STORE_KEY));
    }

    public set lastAction(value) {
        this._storageService.setStored(this.STORE_KEY, value);
    }

    public initListener(): void {
        this._ngZone.runOutsideAngular(() => {
            document.body.addEventListener('click', () => this.reset());
            document.body.addEventListener('mousemove', () => this.reset());
            document.body.addEventListener('keydown', () => this.reset());
        });
    }

    private initInterval(): void {
        this._ngZone.runOutsideAngular(() => {
            setInterval(() => {
                this.check();
            }, this.CHECK_INTERVALL);
        });
    }

    private reset(): void {
        this.lastAction = Date.now();
    }

    private check(): void {
        const now = Date.now();
        const timeLeft = this.lastAction + this.MINUTES_UNTIL_AUTO_LOGOUT * 60 * 1000;
        const diff = timeLeft - now;
        const isTimeout = diff < 0;

        this._ngZone.run(() => {
            if (isTimeout) {
                this._authService.logout();
            }
        });
    }
}
