import {Component, inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DialogService} from '../../../../services/dialog.service';
import {User} from '../../../../models/user';
import {AVAILABLE_MARKETS_ENUM} from '../../../../../config/markets/available-markets';
import {UserService} from '../../../../services/user.service';
import {MultiRequest} from '../../../../utils/multi-request';
import {HttpClient} from '@angular/common/http';

@Component({
    selector: 'app-import-users',
    templateUrl: './import-users.component.html',
    styleUrls: ['./import-users.component.scss']
})
export class ImportUsersComponent implements OnInit {

    public importUsersFormGroup: FormGroup;
    public readonly acceptedTypes = '.csv';
    public importResults?: {positiveResults: User[], negativeResults: User[]};

    private currentlyUploadedFile: File;
    private _fb: FormBuilder = inject(FormBuilder);
    private _userService: UserService = inject(UserService);
    private _dialogService: DialogService = inject(DialogService);
    private _httpClient: HttpClient = inject(HttpClient);

    public ngOnInit() {
        this.importUsersFormGroup = this._fb.group({
            usersFileCtrl: [null, [Validators.required]]
        });
    }

    public onSubmit(): void {
        const fr = new FileReader();
        fr.onload = (stream) => console.log(stream);
        fr.onloadend = () => this.beginRegisteringUsers(this.parseUsers(fr.result.toString()));
        fr.readAsText(this.currentlyUploadedFile);
    }

    public parseUsers(txt: string): User[] {
        const users: Partial<User>[] = [];
        let tempUser: Partial<User> = this.getPartialUser();
        for (const userVal of txt.split('\r\n')) {
            const userProps = userVal.split(',');
            if (userProps.length > 1) {
                [tempUser.contactName, tempUser.email, tempUser.finCode] = [userProps[0], userProps[1], userProps[2]];
                users.push(tempUser);
            }
            tempUser = this.getPartialUser();
        }
        return users as User[];
    }

    private beginRegisteringUsers(users: User[]): void {
        const mr: MultiRequest<User> = new MultiRequest(users, this._userService.postUser, this._httpClient);
        mr.call().subscribe({
            next: (responses) => this.importResults = responses,
            error: (e) => console.log(e)
        });
    }

    private getPartialUser(): Partial<User> {
        return {
            marketId: AVAILABLE_MARKETS_ENUM.UNITED_KINGDOM,
            administrator: false,
            locale: 'en'
        };
    }

    public updateCurrentFileName(fileEvent: any): void {
        if (fileEvent && fileEvent?.target?.files[0].name.endsWith(this.acceptedTypes)) {
            this.currentlyUploadedFile = fileEvent?.target?.files[0];
        } else {
            const dialogContent = this._dialogService.getNewDialogContent('error', 'ADMIN.WRONG_FILE_EXTENSION',
                null, 'ADMIN.UPLOAD_FILE_IN_REQUIRED_FORMAT', this.acceptedTypes.replace('.', ''));
            this._dialogService.openInfoDialog(dialogContent);
        }
    }

}
