import {FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

// form controls must follow naming: 'publicationDate'
export function minDateValidator(): ValidatorFn {
    return (form: FormGroup): ValidationErrors | null => {
        const publicationDateCtrlValue: NgbDateStruct = form.get('publicationDate').value;
        const ctrlDate = new Date(publicationDateCtrlValue.year, publicationDateCtrlValue.month - 1, publicationDateCtrlValue.day);
        return ctrlDate.getTime() < Date.now() ? {pastDate: true} : null;
    };
}
