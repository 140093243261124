import {Component, inject} from '@angular/core';
import {SpinnerService} from '../../../services/spinner.service';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'app-fnp-spinner',
    templateUrl: './fnp-spinner.component.html',
    styleUrls: ['./fnp-spinner.component.scss']
})
export class FnpSpinnerComponent {
    private _spinnerService: SpinnerService = inject(SpinnerService);

    public get loading(): BehaviorSubject<boolean> {
        return this._spinnerService.loading$;
    }
}
