<ng-container *ngIf="importResults; then resultsTemplate; else formTemplate"></ng-container>

<ng-template #formTemplate>
    <form
            class="import-users"
            [formGroup]="importUsersFormGroup"
            (ngSubmit)="onSubmit()">
        <div class="import-users__info">
            <label for="importUsersI">{{ 'USERS.IMPORT_USERS_HERE' | translate }}:</label>
        </div>
        <input
                id="importUsersI"
                type="file"
                class="form-control"
                formControlName="usersFileCtrl"
                (change)="updateCurrentFileName($event)">
        <button
                type="submit"
                class="btn fnp-btn-primary"
                [disabled]="importUsersFormGroup.invalid"
        >{{ "USERS.IMPORT" | translate | uppercase }}
        </button>

    </form>
</ng-template>

<ng-template #resultsTemplate>
    <div class="import-results">
        <div class="import-results__positive">
            <h2>{{ 'USERS.SUCCESS' | translate }}</h2>
            <p *ngFor="let user of importResults.positiveResults">{{ user.email }}</p>
        </div>
        <span class="import-results__spacer"></span>
        <div class="import-results__negative">
            <h2>{{ 'USERS.ERROR' | translate }}</h2>
            <p *ngFor="let user of importResults.negativeResults">{{ user.email }}</p>
        </div>
    </div>
</ng-template>