import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './utils/guards/auth.guard';
import {ROLE} from './utils/enums/role';
import {logoutGuard} from './utils/guards/logout.guard';
import {RegistrationComponent} from './views/body/registration/registration.component';
import {MyDetailsComponent} from './views/body/my-details/my-details.component';
import {UsersManagementComponent} from './views/body/users-management/users-management.component';
import {AdminComponent} from './views/body/admin/admin.component';
import {IntroductionAndMapComponent} from './views/body/introduction-and-map/introduction-and-map.component';
import {FnpComponent} from './views/body/fnp/fnp.component';
import {NoauthGuard} from './utils/guards/noauth.guard';
import {defaultRouteGuard} from './utils/guards/default-route.guard';
import {PrivacyPolicyComponent} from './views/body/privacy-policy/privacy-policy.component';
import {HelpDeskComponent} from './views/body/help-desk/help-desk.component';

const routes: Routes = [
    {
        path: '',
        canActivate: [defaultRouteGuard],
        runGuardsAndResolvers: 'always',
        children: [
            {path: 'logout', children: [], canActivate: [logoutGuard]},
            {
                path: 'intro',
                component: IntroductionAndMapComponent,
                canActivate: [AuthGuard],
                data: {role: ROLE.USER}
            },
            {
                path: 'fnp',
                component: FnpComponent,
                canActivate: [AuthGuard],
                data: {role: ROLE.USER}
            },
            {
                path: 'my-details',
                component: MyDetailsComponent,
                canActivate: [AuthGuard],
                data: {role: ROLE.USER}
            },
            {
                path: 'users-management',
                component: UsersManagementComponent,
                canActivate: [AuthGuard],
                data: {role: ROLE.ADMIN}
            },
            {
                path: 'registration',
                component: RegistrationComponent,
                canActivate: [NoauthGuard]
            },
            {
                path: 'privacy-policy',
                component: PrivacyPolicyComponent,
                canActivate: [NoauthGuard]
            },
            {
                path: 'help-desk',
                component: HelpDeskComponent,
                canActivate: [NoauthGuard]
            },
            {
                path: 'admin',
                component: AdminComponent,
                canActivate: [AuthGuard],
                data: {role: ROLE.ADMIN}
            }
        ]
    },
    {
        path: '**',
        redirectTo: ''
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
