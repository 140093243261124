import {Component, inject, Input} from '@angular/core';
import {AVAILABLE_MARKETS} from '../../../../config/markets/available-markets';
import {ActivatedRoute, Router} from '@angular/router';
import {FnpLanguageService} from '../../../services/fnp-language.service';

@Component({
    selector: 'app-multimarket-view',
    templateUrl: './multimarket-view.component.html',
    styleUrls: ['./multimarket-view.component.scss']
})
export class MultimarketViewComponent {

    @Input()
    title: string;
    @Input()
    marketField: string;
    @Input()
    linkType: 'link' | 'mail' = 'link';

    public availableMarkets = AVAILABLE_MARKETS;
    private readonly _router: Router = inject(Router);
    private readonly _route: ActivatedRoute = inject(ActivatedRoute);
    private readonly _languageService: FnpLanguageService = inject(FnpLanguageService);

    constructor() {
        this.setLocale();
    }

    private setLocale(): void {
        const lang = this._route.snapshot.queryParams['lang'];
        setTimeout(() => this._languageService.use(lang), 300);
    }

    public back(): void {
        this._router.navigate(['login']);
    }

    public getStyle(): string {
        return 'calc((100vw - 8rem - ' + ((this.availableMarkets.length - 1) * 2.5) +
            'rem)/' + this.availableMarkets.length + ')';
    }

}
