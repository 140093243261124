<div class="admin">
    <div class="admin__title">
        <h2>{{ "ADMIN.ADMIN" | translate }}</h2>
    </div>
    <div class="admin__tables">

        <app-files-table
                class="admin__tables__table"
                [fileData]="serviceFilesData"
                [tableTitle]="'ADMIN.SERVICE_FILE_UPLOAD'"
                [acceptedTypes]="serviceFileType"
                [errorMessage]="serviceErrorMessage"
                (uploadFile$)="uploadServiceFile($event)"
                (deleteFile$)="deleteServiceFile($event)"
                (updateFile$)="updateServiceFile($event)"
        ></app-files-table>

        <app-files-table
                class="admin__tables__table"
                [fileData]="repairFilesData"
                [tableTitle]="'ADMIN.REPAIR_FILE_UPLOAD'"
                [acceptedTypes]="repairFileType"
                [errorMessage]="repairErrorMessage"
                (uploadFile$)="uploadRepairFile($event)"
                (deleteFile$)="deleteRepairFile($event)"
                (updateFile$)="updateRepairFile($event)"
        ></app-files-table>


        <app-files-table
                class="admin__tables__table"
                [fileData]="newsletterFilesData"
                [tableTitle]="'ADMIN.NEWSLETTER_FILE_UPLOAD'"
                [acceptedTypes]="newsletterFileType"
                [errorMessage]="newsletterErrorMessage"
                (uploadFile$)="uploadNewsletterFile($event)"
                (deleteFile$)="deleteNewsletterFile($event)"
                (updateFile$)="updateNewsletterFile($event)"
        ></app-files-table>

        <app-files-table
                [appFeatureFlag]="this.ADDITIONAL_FILES_FLAG_NAME"
                class="admin__tables__table"
                [fileData]="additionalFilesData"
                [tableTitle]="'ADMIN.ADDITIONAL_FILE_UPLOAD'"
                [acceptedTypes]="additionalFileType"
                [errorMessage]="additionalErrorMessage"
                (uploadFile$)="uploadAdditionalFile($event)"
                (deleteFile$)="deleteAdditionalFile($event)"
                (updateFile$)="updateAdditionalFile($event)"
        ></app-files-table>

        <div class="admin__tables__report-button">
            <button
                    id="rep-btn"
                    type="button"
                    class="btn fnp-btn-secondary"
                    (click)="openDownloadReportModal(downloadModal)"
            >{{ "ADMIN.GENERATE_FNP_TRANSACTION_REPORT" | translate | uppercase }}
            </button>
        </div>
    </div>
</div>
<ng-template #downloadModal>
    <app-fnp-modal
            *ngIf="dateRangeFormGroup"
            [title]="'ADMIN.GENERATE_FNP_TRANSACTION_REPORT'"
            [contentTemplate]="dateRangeTemplate"
            [saveAction]="true"
            [modalError]="dateRangeFormGroup.getError('pastDate') ?
            {message: 'INITIAL_DATE_CANNOT_BE_GREATER_THAN_FINAL_DATE'} : dateRangeModalErrorMessage"
            [invalidForm]="dateRangeFormGroup.invalid"
            (submitAction$)="downloadReport()"
    ></app-fnp-modal>
</ng-template>

<ng-template #dateRangeTemplate>
    <form
            class="date-range-form"
            [formGroup]="dateRangeFormGroup">
        <div class="date-range-form__from-control">
            <label
                    class="date-range-form__from-control__label"
                    for="fromDateControl"
            >{{ 'ADMIN.FROM_DATE' | translate }}</label>
            <div class="admin__tables__table-group__table__datepicker input-group">
                <input
                        id="fromDateControl"
                        ngbDatepicker
                        readonly
                        #fromDatepickerInput="ngbDatepicker"
                        class="admin__tables__table-group__table__datepicker__input form-control"
                        placeholder="dd-MM-yyyy"
                        dataformatas="dd-MM-yyyy"
                        formControlName="from"/>
                <button
                        type="button"
                        class="btn btn-outline-secondary admin__tables__table-group__table__datepicker__button"
                        (click)="fromDatepickerInput.toggle()">
                    <fa-icon class="admin__tables__table-group__table__datepicker__button__icon"
                             [icon]="['far', 'calendar-days']"></fa-icon>
                </button>
            </div>
        </div>
        <div class="date-range-form__to-control">
            <label
                    class="date-range-form__to-control__label"
                    for="toDateControl"
            >{{ 'ADMIN.TO_DATE' | translate }}</label>

            <div class="admin__tables__table-group__table__datepicker input-group">
                <input
                        id="toDateControl"
                        ngbDatepicker
                        readonly
                        #toDatepickerInput="ngbDatepicker"
                        class="admin__tables__table-group__table__datepicker__input form-control"
                        placeholder="dd-MM-yyyy"
                        dataformatas="dd-MM-yyyy"
                        formControlName="to"/>
                <button
                        type="button"
                        class="btn btn-outline-secondary admin__tables__table-group__table__datepicker__button"
                        (click)="toDatepickerInput.toggle()">
                    <fa-icon class="admin__tables__table-group__table__datepicker__button__icon"
                             [icon]="['far', 'calendar-days']"></fa-icon>
                </button>
            </div>
        </div>
    </form>
</ng-template>