import {Component, ElementRef, inject, OnInit, ViewChild} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {NewsletterService} from '../../../services/newsletter.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {prepareAndDownloadFileAction, getFilenameFromHeaders, getMessageFromError} from '../../../utils/other/utilities';
import {AuthService} from '../../../services/auth.service';
import {DialogService} from '../../../services/dialog.service';

@Component({
    selector: 'app-newsletter',
    templateUrl: './newsletter.component.html',
    styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {

    @ViewChild('newsletterPopup', {static: true}) newsletterPopup: ElementRef;

    private readonly keycloakService = inject(KeycloakService);
    private newsletterService = inject(NewsletterService);
    private modalService = inject(NgbModal);
    private authService = inject(AuthService);
    private dialogService = inject(DialogService);

    private userEmail?: string;
    private currentModal?: NgbModalRef;

    public ngOnInit(): void {
        const authenticated: boolean | undefined = this.keycloakService.getKeycloakInstance().authenticated;
        if (authenticated) {
            this.userEmail = this.authService.userEmail;
            this.newsletterService.isNewsletterRead(this.userEmail).subscribe({
                next: (isRead) => {
                    if (!isRead) {
                        this.currentModal = this.modalService.open(this.newsletterPopup,
                            {
                                backdrop: 'static',
                                centered: false,
                                keyboard: true,
                                container: '#newsPopup'
                            });
                    }
                },
                error: (error) => this.showError(error)
            });
        }
    }

    public downloadNewsletterFile(event): void {
        event.preventDefault();
        this.newsletterService.getNewsletterFile().subscribe({
            next: (response) =>
                prepareAndDownloadFileAction(response.body, getFilenameFromHeaders(response.headers), 'application/pdf'),
            error: (error) => this.showErrorFromPossibleBlob(error)
        });
    }

    public setNewsletterRead(): void {
        this.newsletterService.setNewsletterRead(this.userEmail).subscribe({
            next: () => this.currentModal.close(),
            error: (error) => this.showError(error)
        });
    }

    private showErrorFromPossibleBlob(error: any) {
        const errPromise: Promise<string> = (error.error as Blob).text();
        if (errPromise) {
            errPromise.then((finalTxt) => this.showError(finalTxt));
        } else {
            this.showError(error);
        }
    }

    private showError(error: any): void {
        const dialogContent = this.dialogService.getNewDialogContent(
            'error',
            null,
            null,
            `ERROR.${getMessageFromError(error)}`
        );
        this.dialogService.openInfoDialog(dialogContent);
    }
}
