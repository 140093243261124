import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './views/header/header.component';
import {BodyComponent} from './views/body/body.component';
import {FooterComponent} from './views/footer/footer.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbDateParserFormatter, NgbDatepickerI18n, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {RegistrationComponent} from './views/body/registration/registration.component';
import {NavbarComponent} from './views/body/navbar/navbar.component';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {
    faArrowDown,
    faArrowUp,
    faBars,
    faCarSide,
    faChevronDown,
    faChevronUp,
    faCircleCheck,
    faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons';
import {faCalendarDays, faCircleXmark, faTrashCan} from '@fortawesome/free-regular-svg-icons';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {initializeKeycloak} from './utils/other/keycloak-init';
import {MyDetailsComponent} from './views/body/my-details/my-details.component';
import {UsersManagementComponent} from './views/body/users-management/users-management.component';
import {FnpPaginatorComponent} from './views/body/users-management/fnp-paginator/fnp-paginator.component';
import {FnpModalComponent} from './views/shared/fnp-modal/fnp-modal.component';
import {FnpDialogComponent} from './views/shared/fnp-dialog/fnp-dialog.component';
import {CookieConsentComponent} from './views/shared/cookie-consent/cookie-consent.component';
import {CookieService} from 'ngx-cookie-service';
import {AdminComponent} from './views/body/admin/admin.component';
import {
    BootstrapDatepickerParserFormatter
} from './utils/datepicker-override/bootstrap-datepicker-parser-formatter.service';
import {BootstrapTranslatedDatepicker} from './utils/datepicker-override/bootstrap-translated-datepicker';
import {FilesTableComponent} from './views/body/admin/files-table/files-table.component';
import {UploadFileComponent} from './views/body/admin/files-table/upload-file/upload-file.component';
import {IntroductionAndMapComponent} from './views/body/introduction-and-map/introduction-and-map.component';
import {PasswordInputComponent} from './views/shared/password-input/password-input.component';
import {FnpComponent} from './views/body/fnp/fnp.component';
import {LettersNumbersInputDirective} from './utils/directives/letters-numbers-input.directive';
import {NewsletterComponent} from './views/body/newsletter/newsletter.component';
import {JobInfoTableComponent} from './views/body/fnp/job-info-table/job-info-table.component';
import {VehicleTableComponent} from './views/body/fnp/vehicle-table/vehicle-table.component';
import {FnpPrintComponent} from './views/body/fnp/fnp-print/fnp-print.component';
import {FnpSpinnerComponent} from './views/shared/fnp-spinner/fnp-spinner.component';
import {PrivacyPolicyComponent} from './views/body/privacy-policy/privacy-policy.component';
import {FnpSelectComponent} from './views/shared/fnp-select/fnp-select.component';
import {UnAuthInterceptor} from './utils/interceptors/un-auth-interceptor.service';
import {SpinnerInterceptor} from './utils/interceptors/spinner.interceptor';
import {RepeatTimesDirective} from './utils/directives/repeat-times.directive';
import {FeatureFlagDirective} from './utils/directives/feature-flag.directive';
import {CurrencyPipe} from './utils/pipes/currency.pipe';
import {LanguageParamInterceptor} from './utils/interceptors/language-param.interceptor';
import {ImportUsersComponent} from './views/body/users-management/import-users/import-users.component';
import {ReplacePipe} from './utils/pipes/replace.pipe';
import {HelpDeskComponent} from './views/body/help-desk/help-desk.component';
import {MultimarketViewComponent} from './views/shared/multimarket-view/multimarket-view.component';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        BodyComponent,
        FooterComponent,
        RegistrationComponent,
        NavbarComponent,
        MyDetailsComponent,
        UsersManagementComponent,
        FnpPaginatorComponent,
        FnpModalComponent,
        FnpDialogComponent,
        CookieConsentComponent,
        NewsletterComponent,
        AdminComponent,
        FilesTableComponent,
        UploadFileComponent,
        IntroductionAndMapComponent,
        FnpComponent,
        JobInfoTableComponent,
        VehicleTableComponent,
        FnpPrintComponent,
        RepeatTimesDirective,
        FnpSpinnerComponent,
        PrivacyPolicyComponent,
        FnpSelectComponent,
        FeatureFlagDirective,
        CurrencyPipe,
        ImportUsersComponent,
        ReplacePipe,
        HelpDeskComponent,
        MultimarketViewComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        HttpClientModule,
        KeycloakAngularModule,
        FontAwesomeModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            defaultLanguage: 'de'
        }),
        PasswordInputComponent,
        LettersNumbersInputDirective
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService]
        },
        {provide: NgbDateParserFormatter, useClass: BootstrapDatepickerParserFormatter},
        {provide: NgbDatepickerI18n, useClass: BootstrapTranslatedDatepicker},
        {provide: HTTP_INTERCEPTORS, useClass: UnAuthInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: LanguageParamInterceptor, multi: true},
        CookieService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(fa: FaIconLibrary) {
        fa.addIcons(faBars, faChevronDown, faChevronUp, faCircleXmark, faArrowDown, faArrowUp,
            faTriangleExclamation, faCircleCheck, faTrashCan, faCalendarDays, faCarSide);
    }
}
