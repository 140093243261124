import {Component, DestroyRef, forwardRef, inject, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule} from '@angular/forms';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {debounceTime, noop, tap} from 'rxjs';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faEye, faEyeSlash} from '@fortawesome/free-regular-svg-icons';

@Component({
    selector: 'app-password-input',
    templateUrl: './password-input.component.html',
    styleUrls: ['./password-input.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        FormsModule,
        FontAwesomeModule
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PasswordInputComponent),
            multi: true
        }
    ]
})
export class PasswordInputComponent implements ControlValueAccessor, OnInit {

    public formControl: FormControl = new FormControl<string>('');
    public destroyRef: DestroyRef = inject(DestroyRef);
    public onChange: (value: string) => void = noop;
    public onTouch: () => void = noop;

    public inputType: 'text' | 'password' = 'password';

    constructor(fa: FaIconLibrary) {
        fa.addIcons(faEye, faEyeSlash);
    }

    public ngOnInit(): void {
        this.formControl.valueChanges
            .pipe(
                debounceTime(200),
                tap(value => this.onChange(value)),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe();
    }

    public registerOnChange(fn: () => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    public writeValue(val: string): void {
        this.formControl.setValue(val, {emitEvent: false});
    }

    public setDisabledState(isDisabled: boolean) {
        isDisabled ? this.formControl.disable() : this.formControl.enable();
    }

    public toggleInputType(): void {
        this.inputType = this.inputType === 'text' ? 'password' : 'text';
    }
}
