import {Component, EventEmitter, inject, Input, Output, TemplateRef, ViewChild} from '@angular/core';
import {NgbDateStruct, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FnpFileI, FnpFileStatus, UpdateFnpFile, UploadFnpFile} from '../../../../models/fnp-file';
import {DialogService} from '../../../../services/dialog.service';
import {getDatepickerStructFromStringDate} from '../../../../utils/other/utilities';

@Component({
    selector: 'app-files-table',
    templateUrl: './files-table.component.html',
    styleUrls: ['./files-table.component.scss']
})
export class FilesTableComponent {

    @ViewChild('deleteModalTemplate', {static: true}) deleteModalTemplateRef: TemplateRef<any>;

    @Input() public fileData: FnpFileI[];
    @Input() public tableTitle: string;
    @Input() public acceptedTypes: string;
    @Input() public errorMessage?: { message: string };
    @Output() public uploadFile$: EventEmitter<UploadFnpFile> = new EventEmitter<UploadFnpFile>();
    @Output() public deleteFile$: EventEmitter<FnpFileI> = new EventEmitter<FnpFileI>();
    @Output() public updateFile$: EventEmitter<UpdateFnpFile> = new EventEmitter<UpdateFnpFile>();

    public fileToBeDeleted?: FnpFileI;
    public modalInstance?: NgbModalRef;
    public openModal$: EventEmitter<void> = new EventEmitter<void>();

    private _dialogService: DialogService = inject(DialogService);
    private _modalService: NgbModal = inject(NgbModal);

    public openUploadModal(): void {
        this.openModal$.emit();
    }

    public uploadFile(uploadFnpFile: UploadFnpFile) {
        this.uploadFile$.emit(uploadFnpFile);
    }

    public deleteFile(fnpFile: FnpFileI) {
        delete this.errorMessage;
        this.fileToBeDeleted = fnpFile;
        this.modalInstance = this._modalService.open(this.deleteModalTemplateRef, {
            backdrop: 'static',
            centered: true,
            keyboard: true
        });
    }

    public flushFileToBeDeleted(): void {
        delete this.fileToBeDeleted;
    }

    public performDeleteFile(): void {
        this.deleteFile$.emit(this.fileToBeDeleted);
        this.modalInstance.close();
        this.flushFileToBeDeleted();
    }


    public isFileWaiting(fileStatus: FnpFileStatus): boolean {
        return fileStatus.toUpperCase() === FnpFileStatus.WAITING;
    }

    public updatePublicationDate(fnpFile: FnpFileI, publicationDate: NgbDateStruct): void {
        let publicationDateTimestamp = Date.UTC(publicationDate.year, publicationDate.month - 1, publicationDate.day);
        if (publicationDateTimestamp < Date.now()) {
            const dialogContent = this._dialogService.getNewDialogContent('error', 'GLOBAL.ERROR_HAS_OCCURRED',
                null, 'ADMIN.DATE_CAN_NOT_BE_IN_THE_PAST');
            this._dialogService.openInfoDialog(dialogContent);
            (document.getElementById('datepicker_' + fnpFile.fileName) as HTMLInputElement)!.value = this.getDatepickerStruct(fnpFile.publicationDate);
        } else {
            publicationDateTimestamp = publicationDateTimestamp / 1000;
            this.updateFile$.emit({id: fnpFile.id, fileName: fnpFile.fileName, publicationDateTimestamp});
        }
    }

    public getDatepickerStruct(stringDate: any): string {
        return getDatepickerStructFromStringDate(stringDate);
    }

    public get addDisabledState(): boolean {
        if (!this.fileData) return true;
        for (let i = 0; i < this.fileData.length; i++) {
            if (this.fileData[i].status.toUpperCase() === FnpFileStatus.WAITING) return true;
        }
        return false;
    }

}
