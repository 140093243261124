import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class LanguageParamInterceptor implements HttpInterceptor {

    private readonly LANGUAGE_PARAM_NAME = 'language';

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request.clone({
            url: this.removeMarketSpecificLanguage(request.url)
        }));
    }

    private removeMarketSpecificLanguage(url: string): string {
        if (!url.includes(this.LANGUAGE_PARAM_NAME)) return url;
        const dividedUrl = url.split('?');
        const mainRequestPart = dividedUrl[0];
        const argsParts = dividedUrl[1]?.split('&');
        const langIdx = argsParts.findIndex(param => param.split('=')[0] === this.LANGUAGE_PARAM_NAME);
        if (langIdx !== -1) argsParts[langIdx] = argsParts[langIdx].split('_')[0];
        return mainRequestPart + '?' + argsParts.join('&');
    }
}
