import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {MarketService} from '../../../services/market.service';
import {
    getFilenameFromHeaders,
    getMessageFromError,
    prepareAndDownloadFileAction
} from '../../../utils/other/utilities';
import {FileService} from '../../../services/file.service';
import {DialogService} from '../../../services/dialog.service';

@Component({
    selector: 'app-introduction-and-map',
    templateUrl: './introduction-and-map.component.html',
    styleUrls: ['./introduction-and-map.component.scss']
})
export class IntroductionAndMapComponent implements OnInit, OnDestroy {

    private readonly _marketService: MarketService = inject(MarketService);
    private readonly _dialogService: DialogService = inject(DialogService);
    private readonly _fileService: FileService = inject(FileService);
    private _subscription$: Subscription;

    public marketCode: string;

    public ngOnInit() {
        this.initMarketWatcher();
    }

    public ngOnDestroy() {
        this._subscription$.unsubscribe();
    }

    private initMarketWatcher(counter?: number) {
        if (this._marketService.asyncCurrentMarket()) {
            this._subscription$ = this._marketService.asyncCurrentMarket().subscribe({
                next: (mar) => {
                    this.marketCode = mar.shortname?.toLowerCase();
                }
            });
        } else if (counter > 10) {
            console.log('Market init timeout ERROR.');
        } else {
            setTimeout(() => this.initMarketWatcher(counter ? counter++ : 1), 100);
        }
    }

    public downloadAdditionalInfoFile(): void {
        this._fileService.getAdditionalInfoFile().subscribe({
            next: (response) => {
                prepareAndDownloadFileAction(response.body, getFilenameFromHeaders(response.headers), 'xls/xlsx');
            },
            error: (error) => this.showErrorFromPossibleBlob(error)
        });
    }

    private showErrorFromPossibleBlob(error: any) {

        const errPromise: Promise<string> = (error.error as Blob).text();
        if (errPromise) {
            errPromise.then((finalTxt) => this.displayErrorModal(finalTxt));
        } else {
            this.displayErrorModal(error);
        }
    }

    private displayErrorModal(errorMessage: string): void {
        const dialogContent = this._dialogService.getNewDialogContent(
            'error',
            null,
            null,
            `ERROR.${getMessageFromError(errorMessage)}`);
        this._dialogService.openInfoDialog(dialogContent, true);
    }


}
