import {CellStyle, ExcelWorksheetForm, WorksheetRow} from '../excel';
import {MarketCurrency} from '../../../models/market';
import * as Excel from 'exceljs/dist/exceljs.min.js';


export class WorksheetBuilder {
    private worksheetName?: string;
    private headers?: Excel.Column[];
    private rows?: any[];
    private outlineHeader?: 'thin' | 'medium' | 'dotted' | 'thick' | 'double';
    private headerStyles?: CellStyle[];
    private insertedRows?: WorksheetRow[] = [];
    private priceColumnProp?: string[];
    private currency?: MarketCurrency;
    private placeholder?: string;

    public setName(worksheetName: string): WorksheetBuilder {
        this.worksheetName = worksheetName;
        return this;
    }

    public setHeaders(headers: Excel.Column[]): WorksheetBuilder {
        this.headers = headers;
        return this;
    }

    public setRows(rows: any[]): WorksheetBuilder {
        this.rows = rows;
        return this;
    }

    public setOutlinedHeader(outline: 'thin' | 'medium' | 'dotted' | 'thick' | 'double'): WorksheetBuilder {
        this.outlineHeader = outline;
        return this;
    }

    public setHeaderStyles(styles: CellStyle[]): WorksheetBuilder {
        this.headerStyles = styles;
        return this;
    }

    public setPriceColumns(columnProps: string[]): WorksheetBuilder {
        this.priceColumnProp = columnProps;
        return this;
    }

    public setCurrency(currency: MarketCurrency): WorksheetBuilder {
        this.currency = currency;
        return this;
    }

    public setPlaceholder(placeholder: string): WorksheetBuilder {
        this.placeholder = placeholder;
        return this;
    }

    public insertRow(rows: WorksheetRow | WorksheetRow[]): WorksheetBuilder {
        if ((rows as [])?.length && (rows as [])?.length > 1) {
            this.insertedRows = rows as WorksheetRow[];
        } else {
            const castedRow = rows as WorksheetRow;
            this.insertedRows = [castedRow];
            if (castedRow?.blankBefore) {
                this.insertedRows.splice(castedRow.position - 1, 0, {position: castedRow.position, values: ''});
                castedRow.position++;
            }
            if (castedRow?.blankAfter) this.insertedRows.push({
                position: castedRow.position + 1,
                values: ''
            });
        }
        return this;
    }

    public build(): ExcelWorksheetForm {
        if (!this.worksheetName || !this.rows) {
            throw 'Build failed. Missing properties to create Worksheet.';
        }
        return {
            worksheetName: this.worksheetName,
            headers: this.headers,
            rows: this.rows,
            insertedRows: this.insertedRows,
            outlineHeaderStyle: this.outlineHeader,
            headerStyles: this.headerStyles,
            priceColumns: this.priceColumnProp,
            currency: this.currency,
            placeholder: this.placeholder
        };
    }
}

