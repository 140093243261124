import {KeycloakService} from 'keycloak-angular';
import {environment} from '../../../config/environments/environment';

export function initializeKeycloak(keycloak: KeycloakService) {
    return () =>
        keycloak.init({
            config: {
                url: environment.keycloak.url,
                realm: environment.keycloak.realm,
                clientId: environment.keycloak.clientId
            },
            loadUserProfileAtStartUp: true,
            enableBearerInterceptor: true,
            initOptions: {
                onLoad: 'check-sso',
                checkLoginIframe: false,
                locale: 'de',
                pkceMethod: 'S256'
            }
        });
}
