export interface PageableI {
    pageNumber: number,
    pageSize: number,
    pages?: number
}

export class Pageable implements PageableI {
    pageNumber: number;
    pageSize: number;
    pages?: number;

    constructor() {
        this.pageNumber = 1;
        this.pageSize = 10;
        this.pages = 1;
    }
}
