import {Router} from '@angular/router';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NoauthGuard extends KeycloakAuthGuard {

  constructor(
    protected override readonly router: Router,
    private readonly keycloak: KeycloakService) {
    super(router, keycloak);
  }

  public async isAccessAllowed(): Promise<boolean> {
    const authenticated: boolean | undefined = this.keycloak.getKeycloakInstance().authenticated;
    if (authenticated) {
      await this.keycloak.logout().catch(e => console.error(e));
    }
    return true;
  }
}
