import {Component, inject, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Subject, Subscription} from 'rxjs';
import {IconName} from '@fortawesome/fontawesome-svg-core';
import {User} from '../../../models/user';
import {SearchCriteria} from '../../../models/search-criteria';
import {Company} from '../../../models/company';
import {SORT_TYPE} from '../../../utils/enums/sort-type';
import {UserService} from '../../../services/user.service';
import {CompanyService} from '../../../services/company.service';
import {DialogService} from '../../../services/dialog.service';
import {FnpLanguageService} from '../../../services/fnp-language.service';
import {getMessageFromError} from '../../../utils/other/utilities';
import {MarketService} from '../../../services/market.service';

@Component({
    selector: 'app-users-management',
    templateUrl: './users-management.component.html',
    styleUrls: ['./users-management.component.scss']
})
export class UsersManagementComponent implements OnInit, OnDestroy {

    @ViewChild('deleteModalTemplate', {static: true}) deleteModalTemplateRef: TemplateRef<any>;

    public filtersFormGroup!: FormGroup;
    public companyFormGroup: FormGroup;
    public userFormGroup: FormGroup;
    public usersData: User[];
    public searchCriteria: SearchCriteria = new SearchCriteria();
    public checkboxSet: Set<string> = new Set<string>();
    public modalError?: { message: string };
    public recordsCount = 0;

    private currentModal?: NgbModalRef;

    private _userService: UserService = inject(UserService);
    private _companyService: CompanyService = inject(CompanyService);
    private _formBuilder: FormBuilder = inject(FormBuilder);
    private _modalService: NgbModal = inject(NgbModal);
    private _dialogService: DialogService = inject(DialogService);
    private _languageService: FnpLanguageService = inject(FnpLanguageService);
    private _marketService: MarketService = inject(MarketService);
    private _marketSubscription$: Subscription;

      constructor() {
        this.loadUsers();
      }

      public ngOnInit(): void {
        this.initFormGroup();
        this._marketSubscription$ = this._marketService.stateUpdate.subscribe({
          next: () => {
            this.resetFilters();
          }
        });
      }

    public ngOnDestroy() {
      this._marketSubscription$.unsubscribe();
    }

    private initFormGroup(): void {
        this.filtersFormGroup = this._formBuilder.group({
            finCode: [[], [Validators.pattern('^[A-Za-z0-9]+$')]],
            companyName: [[]],
            email: [[]],
            contactName: [[]]
        });
    }

    public pageReloadTrigger(): void {
        this.toggleOffAll();
        this.loadUsers();
    }

    public loadUsers(): void {
        this._userService.getUsers(this.searchCriteria).subscribe({
            next: (response) => {
                this.usersData = response.retrievedUserDtoList;
                this.recordsCount = response.size;
                this.searchCriteria.pageable.pages = response.totalPages;
            },
            error: (error) => this.displayErrorModal(error)
        });
    }

    public addUser(): void {
        const user: User = {...this.userFormGroup.value};
        user.marketId = this._marketService.currentMarket.id;
        this._userService.postUser(user).subscribe({
            next: () => {
                this.clearErrorMessage();
                this.loadUsers();
            },
            error: (error) => this.modalError = {message: getMessageFromError(error)},
            complete: () => {
                this.currentModal.close();
                this.displaySuccessCreate();
            }
        });
    }

    public addCompany(): void {
        const company: Company = {...this.companyFormGroup.value};
        company.marketId = this._marketService.currentMarket.id;
        this._companyService.postCompany(company).subscribe({
            next: () => {
                this.clearErrorMessage();
            },
            error: (error) => this.modalError = {message: getMessageFromError(error)},
            complete: () => {
                this.currentModal.close();
                this.displaySuccessCreate();
            }
        });
    }

    public deleteUsers(): void {
        this.currentModal = this._modalService.open(this.deleteModalTemplateRef, {
            backdrop: 'static',
            centered: true,
            keyboard: true
        });
    }

    public performDeleteUsers(): void {
        this.currentModal.close();
        const usersHelper: PartialUser[] = this.getUserHelper();
        const usersFailedToDelete: { id: string, email: string }[] = [];
        let observableCounter = usersHelper.length;
        const observableCounter$: Subject<void> = new Subject<void>();

        const subscription = observableCounter$.subscribe(() => {
            observableCounter--;
            if (observableCounter === 0) {
                this.displayDeleteResults(usersFailedToDelete, usersHelper.length);
                subscription.unsubscribe();
            }
        });

        usersHelper.forEach(user => this._userService.deleteUser(user.id)
            .subscribe({
                next: () => {
                    this.deleteUserFromData(user.id);
                    this.recordsCount--;
                    observableCounter$.next();
                },
                error: () => {
                    usersFailedToDelete.push(user);
                    observableCounter$.next();
                }
            }));
    }

    private getUserHelper(): PartialUser[] {
        return this.usersData.filter(user =>
            (document.getElementsByName('checkbox_' + user.id)[0] as HTMLInputElement).checked
        ).map((user) => {
            return {
                id: user.id,
                email: user.email
            };
        });
    }

    public displayDeleteResults(usersIdsFailedToDelete: PartialUser[], requestsAmount: number): void {
        if (usersIdsFailedToDelete.length === 0) {
            this.displaySuccessDelete();
        } else if (usersIdsFailedToDelete.length === requestsAmount) {
            this.displayFailedDelete();
        } else {
            this.displayPartialDelete(usersIdsFailedToDelete);
        }
    }

    public toggleAll(): void {
        const toggleVal: boolean = (document.getElementsByName('toggleAllCheckbox')[0] as HTMLInputElement).checked;
        if (!toggleVal) this.checkboxSet.clear();
        this.usersData?.forEach(user => {
            if (toggleVal) this.checkboxSet.add(user.id);
            (document.getElementsByName('checkbox_' + user.id)[0] as HTMLInputElement).checked = toggleVal;
        });
    }

    public toggleSingle(email: string): void {
        const toggleVal: boolean = (document.getElementsByName('checkbox_' + email)[0] as HTMLInputElement).checked;
        if (!toggleVal) (document.getElementsByName('toggleAllCheckbox')[0] as HTMLInputElement).checked = false;
        toggleVal ? this.checkboxSet.add(email) : this.checkboxSet.delete(email);
    }

    public toggleOffAll(): void {
        (document.getElementsByName('toggleAllCheckbox')[0] as HTMLInputElement).checked = false;
        this.toggleAll();
    }

    public deleteUserFromData(userId: string): void {
        for (let i = 0; i < this.usersData.length; i++) {
            if (this.usersData[i].id === userId) {
                this.usersData.splice(i, 1);
                break;
            }
        }
    }

    // Modals

    public openCompanyModal(modalTemplate: any): void {
        this.companyFormGroup = this._formBuilder.group({
            fin: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9]+$')]],
            name: ['', [Validators.required]]
        });
        this.openModal(modalTemplate);
    }

    public openUserModal(modalTemplate: TemplateRef<any>): void {
        this.userFormGroup = this._formBuilder.group({
            finCode: ['', [Validators.required]],
            contactName: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            administrator: [false, []]
        });
        this.openModal(modalTemplate);
    }

    public openImportModal(modalTemplate:TemplateRef<any>): void{
          this.openCompanyModal(modalTemplate);
    }

    private openModal(modalTemplate: any): void {
        this.currentModal = this._modalService.open(modalTemplate, {
            backdrop: 'static',
            centered: true,
            keyboard: true
        });
        this.currentModal.dismissed.subscribe(() => this.clearErrorMessage());
    }

    //Notification

    private displaySuccessCreate(): void {
        const dialogContent = this._dialogService.getNewDialogContent('success', null, null, 'USERS.DATA_SUCCESSFULLY_UPDATED');
        this._dialogService.openInfoDialog(dialogContent, true);
    }

    private displaySuccessDelete(): void {
        const dialogContent = this._dialogService.getNewDialogContent('success', null, null, 'USERS.RECORDS_SUCCESSFULLY_DELETED');
        this._dialogService.openInfoDialog(dialogContent, true);
    }

    private displayPartialDelete(usersIdsFailedToDelete: { id: string, email: string }[]): void {
        let failedUsersTxt = `${this._languageService.translate('USERS.COULD_NOT_DELETED_USERS')}:\n`;
        usersIdsFailedToDelete.forEach((user, idx) => {
            failedUsersTxt = failedUsersTxt + user.email;
            if (idx !== usersIdsFailedToDelete.length - 1) {
                failedUsersTxt = failedUsersTxt + ',\n';
            }
        });
        const dialogContent = this._dialogService.getNewDialogContent('warning', null, null, 'USERS.RECORDS_PARTIALLY_DELETED', failedUsersTxt);
        this._dialogService.openInfoDialog(dialogContent, true, 6000);
    }

    private displayFailedDelete(): void {
        const dialogContent = this._dialogService.getNewDialogContent('error', null, null, 'USERS.RECORDS_NOT_DELETED');
        this._dialogService.openInfoDialog(dialogContent, true);
    }

    private displayErrorModal(error: any): void {
        const dialogContent = this._dialogService.getNewDialogContent(
            'error',
            null,
            null,
            `ERROR.${getMessageFromError(error)}`
        );
        this._dialogService.openInfoDialog(dialogContent, true);
    }

    public clearErrorMessage(): void {
        delete this.modalError;
    }

    // Sort & Filter

    public applyFilters(): void {
        this.searchCriteria.flushFilters();
        Object.entries(this.filtersFormGroup.getRawValue()).forEach((ctrl: [string, string]) => {
            if (ctrl[1]?.length) this.searchCriteria.addFilter(ctrl[0], ctrl[1] as (string | number));
        });
        this.resetPagination();
        this.toggleOffAll();
        this.loadUsers();
    }

    private resetPagination(): void {
        this.searchCriteria.pageable.pageNumber = 1;
    }

    public resetFilters(): void {
        this.filtersFormGroup.reset();
        this.searchCriteria.flushAll();
        this.toggleOffAll();
        this.loadUsers();
    }

    public sortBy(columnName: string): void {
        this.toggleOffAll();
        this.searchCriteria.applySort(columnName, this.getSortType(columnName));
        this.loadUsers();
    }

    private getSortType(columnName: string): SORT_TYPE {
        return (this.searchCriteria.sortBy?.sortColumn === columnName && this.searchCriteria.sortBy?.sortType) === SORT_TYPE.ASC ? SORT_TYPE.DESC : SORT_TYPE.ASC;
    }

    public getSortDirection(): IconName {
        return this.searchCriteria.sortBy.sortType === SORT_TYPE.ASC ? 'arrow-down' : 'arrow-up';
    }

    public get currentPages(): string {
        const firstPage = (this.searchCriteria.pageable.pageNumber - 1) * this.searchCriteria.pageable.pageSize + 1;
        const lastPage = firstPage + (this.usersData?.length ?? 0) - 1;
        return `${firstPage} - ${lastPage}`;
    }

}

export interface PartialUser {
    id: string,
    email: string
}
