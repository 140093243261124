import {inject, Injectable, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, Subscription} from 'rxjs';
import {KeycloakService} from 'keycloak-angular';
import {KeycloakProfile} from 'keycloak-js';
import {jwtDecode} from 'jwt-decode';
import {ROLE} from '../utils/enums/role';
import {environment} from '../../config/environments/environment';
import {User} from '../models/user';
import {UserService} from './user.service';
import {MarketService} from './market.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService implements OnDestroy {
    public isLogged$: BehaviorSubject<boolean>;

    private _isSubscribing = false;
    private _loggedAppUser: User;
    private _router = inject(Router);
    private _keycloakService = inject(KeycloakService);
    private _userService = inject(UserService);
    private _marketService = inject(MarketService);

    constructor() {
        if (this._keycloakService.getKeycloakInstance().authenticated) {
            this._isSubscribing = true;
            this.loadKeycloakProfile().then(async () => {
                this.isLogged$ = new BehaviorSubject<boolean>(await this._keycloakService.isLoggedIn());
            }).then(() => {
                this.subscribeToLoggedSubject();
                this.loadAppUserProfile();
            });
        }
    }

    public ngOnDestroy(): void {
        this.isLogged$.complete();
    }

    private subscribeToLoggedSubject(): void {
        this.isLogged$.subscribe((val: boolean) => {
            if (this._isSubscribing) {
                this._isSubscribing = false;
            } else {
                if (val) {
                    this._router.navigate(['introduction-map']);
                }
            }
        });
    }

    private loadAppUserProfile(): Subscription {
        const subscription = this._userService.getUserByEmail(this.userEmail).subscribe({
            next: (response) => this.handleFetchLoggedUser(response),
            error: (e) => this.handleFetchLoggedUserError(e),
            complete: () => subscription.unsubscribe()
        });
        return subscription;
    }

    private handleFetchLoggedUser(user: User): void {
        this._loggedAppUser = user;
        this._marketService.setMarketById(user.marketId, true);
    }

    private handleFetchLoggedUserError(e: any): void {
        // TODO remove after testing
        console.log('DEBUG: Could not fetch user profile.');
        console.log(e);
        this.logout();
    }

    public isCurrentlyLogged(): boolean {
        return this.isLogged$?.getValue() ?? false;
    }

    public logout(): void {
        this._keycloakService.logout().then(() => this.isLogged$.next(false));
    }

    public loadKeycloakProfile(): Promise<KeycloakProfile> {
        return this._keycloakService.loadUserProfile(true);
    }

    public set loggedUserContactName(contactName: string) {
        if (this._loggedAppUser) {
            this._loggedAppUser.contactName = contactName;
        } else {
            this.loadAppUserProfile().add(() => this._loggedAppUser.contactName = contactName);
        }
    }

    public get keycloakLanguage(): string {
        const token = this._keycloakService.getKeycloakInstance()?.token;
        return token ? jwtDecode(token)['locale'] : null;
    }

    public get userName(): string {
        return this._keycloakService.getKeycloakInstance()?.profile?.username;
    }

    public get userEmail(): string {
        return this._keycloakService.getKeycloakInstance().profile.email;
    }

    public get userRole(): ROLE {
        return <ROLE>this._keycloakService.getKeycloakInstance().tokenParsed?.resource_access[environment.keycloak.clientId].roles
            .find(role => role === ROLE.ADMIN) ?? ROLE.USER;
    }

    public get loggedUser(): User {
        return this._loggedAppUser;
    }

}
