<table class="fnp__inner-table">
    <tr class="fnp__inner-table__tr">
        <th
                *ngFor="let header of headers"
                class="fnp__inner-table__th"
                [ngClass]="isEdgeBrowser? 'fnp__inner-table__th--edge' : 'fnp__inner-table__th--default'"
        >
            <span>{{ header | translate }}</span>
        </th>
    </tr>
    <tbody>
    <tr class="fnp__inner-table__body-tr">
        <ng-container *ngIf="currentVehicle; then validTable; else emptyTable"></ng-container>
        <ng-template #validTable>
            <td>{{ currentVehicle?.buildDate | date: 'dd/MM/yyyy'}}</td>
            <td>{{ currentVehicle?.model }}</td>
            <td>{{ currentVehicle?.bodyStyle }}</td>
            <td>{{ currentVehicle?.engineStyle }}</td>
            <td>{{ currentVehicle?.transmission }}</td>
            <td>{{ currentVehicle?.drive }}</td>
        </ng-template>
        <ng-template #emptyTable>
            <td colspan="6" class="placeholder-cell">{{"FNP.SEARCH_FOR_VALID_VEHICLE" | translate}}</td>
        </ng-template>
    </tr>
    </tbody>
</table>