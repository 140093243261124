import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, filter, tap} from 'rxjs';
import {AuthService} from './auth.service';
import {ActivationEnd, NavigationStart, Router, Routes} from '@angular/router';
import {ROLE} from '../utils/enums/role';
import {StorageService} from './storage.service';

@Injectable({
    providedIn: 'root'
})
export class RouteService {

    public routeChecked: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private validRoutes: string[] = [];
    private pageReloaded: { started?: boolean, ended?: boolean, url?: string } = {};

    private _storageService: StorageService = inject(StorageService);
    private _authService: AuthService = inject(AuthService);
    private _router: Router = inject(Router);

    constructor() {
        this.extractRoutes(this._router.config[0].children);
        this.initRouteWatcher();
    }

    private initRouteWatcher(): void {
        this._router.events.pipe(
            filter(evt => evt instanceof NavigationStart || evt instanceof ActivationEnd),
            tap(evt => {
                if (evt instanceof NavigationStart) {
                    this.pageReloaded.url = evt.url;
                    this.pageReloaded.started = true;
                } else this.pageReloaded.ended = true;
            })
        ).subscribe(evt => {
            if (!(this.pageReloaded.started && this.pageReloaded.ended && evt instanceof ActivationEnd)) {
                this._storageService.setStored('FNP_RELOADED', '1');
                this.pageReloaded = {};
            }
        });
    }

    private extractRoutes(routes: Routes): void {
        routes.forEach(route => {
            this.validRoutes.push(route.path);
            if (route.children && route?.children.length > 0) {
                this.extractRoutes(route.children);
            }
        });
    }

    public checkRoute(): boolean {
        const role: ROLE = this._authService.userRole;
        const currentUrl: string = this._router.getCurrentNavigation()
            .extractedUrl
            .toString()
            .split('?')[0];

        if ((this.routeChecked.getValue() || this.isValidReloaded()) && this.isRouteValid(currentUrl.replace('/', ''))) {
            this.routeChecked.next(false);
            this._storageService.setStored('FNP_RELOADED', '0');
            return true;
        } else {
            this.routeChecked.next(true);
            return (role === ROLE.ADMIN && currentUrl === '/users-management')
                || (role === ROLE.USER && currentUrl === '/fnp')
                || (currentUrl.includes('/registration'));
        }
    }

    public isValidReloaded(): boolean {
        return this._storageService.getStored('FNP_RELOADED') === '1';
    }

    public navigateToDefault(): Promise<boolean> {
        return this._router.navigate([this.getDefaultUrlForCurrentRole()]);
    }

    public getDefaultUrlForCurrentRole(): string {
        return this._authService.userRole === ROLE.ADMIN ? '/users-management' : '/fnp';
    }

    public isRouteValid(url: string): boolean {
        return this.validRoutes.findIndex(route => route === url) !== -1;
    }

    public get currentRoute(): string {
        return this._router.url;
    }
}
