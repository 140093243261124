import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'replace',
    pure: true
})
export class ReplacePipe implements PipeTransform {
    transform(value: string, toBeReplaced: string, toReplaceWith: string): string {
        return value.replace(toBeReplaced, toReplaceWith);
    }
}
