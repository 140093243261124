<ng-template #cookieConsentModal>
  <div class="fnp-modal modal show" tabindex="-1" aria-labelledby="cookieConsent"
       style="display: block; padding-right: 15px;" aria-modal="true" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="fnp-modal modal-header">
          <h3 class="mb-0 mt-2" id="exampleModalLabel">{{"COOKIE_CONSENT.TITLE" | translate}}</h3>
        </div>
        <div class="modal-body">
          <br />
          <p>{{"COOKIE_CONSENT.INFO" | translate}}</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn fnp-btn-primary"
            data-dismiss="modal"
            (click)="close()">
            {{"COOKIE_CONSENT.CLOSE" | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
