import {CanActivateFn} from '@angular/router';
import {inject} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {RouteService} from '../../services/route.service';

export const logoutGuard: CanActivateFn = () => {
    const _kcService: KeycloakService = inject(KeycloakService);
    const _routeService: RouteService = inject(RouteService);
    if (_kcService.getKeycloakInstance()?.authenticated) {
        _routeService.navigateToDefault().then(() => _kcService.logout());
    } else{
        _routeService.navigateToDefault().then(() => _kcService.login());
    }
    return true;
};
