<ng-template #newsletterPopup>
  <div class="fnp-modal modal show" tabindex="-1" aria-labelledby="newsletterInfo"
       style="display: block" aria-modal="true" role="dialog">
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-body">
          <div class="news-title">
            <h2>{{"NEWSLETTER.TITLE" | translate}}</h2>
          </div>
          <div class="news-link">
            <a href="newsletter.pdf" tabindex="-1"
               (click)="downloadNewsletterFile($event)">{{"NEWSLETTER.LINK" | translate}}</a>
          </div>
          <div class="news-read">
            <div>{{"NEWSLETTER.CONFIRM" | translate}}</div>
            <input type="checkbox" (click)="setNewsletterRead()" tabindex="-1" />
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
